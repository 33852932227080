export const dayBookTableHeaders = [
  { id: 15, name: "Select All", alias: "all", isShow: false },
  { id: 16, name: "Remove All", alias: "remove", isShow: false },
  { id: 0, name: " ", width: "90", textAlign: "left", isShow: true },
  {
    id: 1,
    name: "S.NO",
    width: "100",
    textAlign: "left",
    isShow: true,
    isFixed: true
  },
  {
    id: 2,
    name: "Transaction Date",
    width: "200",
    textAlign: "left",
    isShow: true,
    isFixed: true
  },
  {
    id: 3,
    name: "Txn Id",
    width: "100",
    textAlign: "left",
    isShow: true,
    isFixed: true
  },
  {
    id: 29,
    name: "Cancel Txn by",
    width: "100",
    textAlign: "left",
    isShow: true,
    isFixed: true,
    alias: "cancledTxnId"
  },
  {
    id: 4,
    name: "Debit Account",
    width: "300",
    textAlign: "left",
    isShow: true,
    isFixed: true
  },
  {
    id: 5,
    name: "Credit Account",
    width: "300",
    textAlign: "left",
    isShow: true,
    isFixed: true
  },
  {
    id: 6,
    name: "Amount",
    width: "120",
    textAlign: "right",
    isShow: true,
    isFixed: true
  },
  {
    id: 14,
    name: "Voucher",
    width: "150",
    textAlign: "left",
    isShow: true,
    isFixed: false
  },
  {
    id: 7,
    name: "Created On",
    width: "300",
    textAlign: "left",
    isShow: true,
    isFixed: false
  },
  {
    id: 8,
    name: "Created By",
    width: "300",
    textAlign: "left",
    isShow: true,
    isFixed: false
  },
  {
    id: 9,
    name: "Status",
    width: "180",
    textAlign: "left",
    isShow: true,
    isFixed: false
  },
  {
    id: 17,
    name: "Debit Account Closing Balance",
    alias: "sourceAccountClosingBalance",
    isShow: true,
    isFixed: false
  },
  {
    id: 18,
    name: "Debit Account Opening Balance",
    alias: "sourceAccountOpeningBalance",
    isShow: true,
    isFixed: false
  },
  {
    id: 19,
    name: "Credit Account Closing Balance",
    alias: "targetAccountClosingBalance",
    isShow: true,
    isFixed: false
  },
  {
    id: 20,
    name: "Credit Account Opening Balance",
    alias: "targetAccountOpeningBalance",
    isShow: true,
    isFixed: false
  },
  {
    id: 21,
    name: "Account Code",
    alias: "accountCodeName",
    isShow: true,
    isFixed: false,
    textAlign: "center",
    width: "180"
  },
  {
    id: 22,
    name: "Transaction Type",
    alias: "accountTransactionTypeName",
    isShow: true,
    isFixed: false,
    textAlign: "center",
    width: "180"
  },
  {
    id: 23,
    name: "Invoice No",
    alias: "invoiceNo",
    isShow: true,
    isFixed: false,
    textAlign: "center",
    width: "180"
  },
  {
    id: 24,
    name: "Due Amount",
    alias: "dueAmount",
    isShow: true,
    isFixed: false,
    textAlign: "right",
    width: "200"
  },
  {
    id: 28,
    name: "Remaining Settled Amount",
    alias: "remainingSettledAmount",
    isShow: true,
    isFixed: false,
    textAlign: "right",
    width: "250"
  },
  {
    id: 25,
    name: "Over Due Days",
    alias: "overDueDays",
    isShow: true,
    isFixed: false,
    textAlign: "center",
    width: "180"
  },
  {
    id: 26,
    name: "Over Due Count",
    alias: "overDueDaysCount",
    isShow: true,
    isFixed: false,
    textAlign: "center",
    width: "180"
  },
  {
    id: 24,
    name: "Payment Status",
    alias: "paymentStatus",
    isShow: true,
    isFixed: false,
    textAlign: "center",
    width: "135"
  },
  {
    id: 10,
    name: "CGST",
    width: "180",
    textAlign: "right",
    isShow: true,
    isFixed: false
  },
  {
    id: 11,
    name: "IGST",
    width: "180",
    textAlign: "right",
    isShow: true,
    isFixed: false
  },
  {
    id: 12,
    name: "SGST",
    width: "180",
    textAlign: "right",
    isShow: true,
    isFixed: false
  },
  {
    id: 13,
    name: "Total Amount",
    width: "180",
    textAlign: "right",
    isShow: true,
    isFixed: false
  }
];

export const trialBalanceSiteWiseTableHeader = [
  { id: 1, name: "Select All", alias: "all", isShow: false },
  { id: 2, name: "Remove All", alias: "remove", isShow: false },
  {
    id: 3,
    name: "Opening Bal",
    alias: "openingBalance",
    isShow: true,
    isFixed: true
  },
  {
    id: 4,
    name: "Current Bal",
    alias: "accountGroupCurrentBalance",
    isShow: true,
    isFixed: true
  },
  {
    id: 5,
    name: "Opening Debit Bal",
    alias: "openingDebitBalance",
    isShow: true,
    isFixed: true
  },
  {
    id: 6,
    name: "Current Debit Bal",
    alias: "accountGroupDebitBalance",
    isShow: true,
    isFixed: true
  },
  {
    id: 7,
    name: "Opening Credit Bal",
    alias: "openingCreditBalance",
    isShow: true,
    isFixed: true
  },
  {
    id: 8,
    name: "Current Credit Bal",
    alias: "accountGroupCreditBalance",
    isShow: true,
    isFixed: false
  },
  {
    id: 9,
    name: "Current Month Credit Bal",
    alias: "currentMonthCreditBalance",
    isShow: true,
    isFixed: false
  },
  {
    id: 10,
    name: "Current Month Debit Bal",
    alias: "currentMonthDebitBalance",
    isShow: true,
    isFixed: false
  }
];

export const trialBalanceTableHeader = [
  { id: 1, name: "Select All", alias: "all", isShow: false },
  { id: 2, name: "Remove All", alias: "remove", isShow: false },
  {
    id: 11,
    name: "S.NO",
    minWidth: "100px",
    textAlign: null,
    isShow: true,
    isFixed: true
  },
  {
    id: 12,
    name: "Name",
    minWidth: "200px",
    textAlign: null,
    isShow: true,
    isFixed: true
  },
  {
    id: 3,
    name: "Opening Bal",
    alias: "openingBalance",
    isShow: true,
    minWidth: "150px",
    textAlign: "right",
    isFixed: true
  },
  {
    id: 4,
    name: "Current Bal",
    alias: "accountGroupCurrentBalance",
    isShow: true,
    minWidth: "150px",
    textAlign: "right",
    isFixed: true
  },
  {
    id: 5,
    name: "Opening Debit Bal",
    alias: "openingDebitBalance",
    isShow: true,
    minWidth: "200px",
    textAlign: "right",
    isFixed: true
  },
  {
    id: 6,
    name: "Current Debit Bal",
    alias: "accountGroupDebitBalance",
    isShow: true,
    minWidth: "200px",
    textAlign: "right",
    isFixed: true
  },
  {
    id: 7,
    name: "Opening Credit Bal",
    alias: "openingCreditBalance",
    isShow: true,
    minWidth: "200px",
    textAlign: "right",
    isFixed: true
  },
  {
    id: 8,
    name: "Current Credit Bal",
    alias: "accountGroupCreditBalance",
    isShow: true,
    minWidth: "200px",
    textAlign: "right",
    isFixed: true
  },
  {
    id: 9,
    name: "Current Month Credit Bal",
    alias: "currentMonthCreditBalance",
    isShow: true,
    minWidth: "200px",
    textAlign: "right",
    isFixed: true
  },
  {
    id: 10,
    name: "Current Month Debit Bal",
    alias: "currentMonthDebitBalance",
    isShow: true,
    minWidth: "200px",
    textAlign: "right",
    isFixed: true
  }
];

export const generalLedgerTableHeader = [
  { id: 1, name: "Select All", alias: "all", isShow: false },
  { id: 2, name: "Remove All", alias: "remove", isShow: false },
  { id: 3, name: "S.NO", minWidth: "100", textAlign: null, isShow: true },
  { id: 4, name: "Txn Id", textAlign: "left", width: "80", isShow: true },
  {
    id: 5,
    name: "Created On",
    textAlign: "left",
    width: "200",
    border: "2px solid red",
    isShow: true
  },
  {
    id: 6,
    name: "Transaction Date",
    textAlign: "left",
    width: "200",
    border: "2px solid red",
    isShow: true
  },
  {
    id: 7,
    name: "Account",
    textAlign: "left",
    width: "240",
    border: "2px solid red",
    isShow: true
  },
  {
    id: 8,
    name: "Voucher",
    textAlign: "left",
    width: "200",
    border: "2px solid red",
    isShow: true
  },
  {
    id: 9,
    name: "Debit",
    textAlign: "right",
    marinLeft: "10px",
    width: "180",
    isShow: true
  },
  {
    id: 10,
    name: "Credit",
    textAlign: "right",
    marinLeft: "10px",
    width: "180",
    isShow: true
  },
  { id: 11, name: "Opening Bal", alias: "openingBalance", isShow: true },
  { id: 12, name: "Closing Bal", alias: "balance", isShow: true },
  // {
  //   id: 113,
  //   name: "Payment Transaction Balance",
  //   alias: "paymentAndTransactionMappingDTO",
  //   textAlign: "center",
  //   isShow: true
  // },
  {
    id: 116,
    name: "Invoice No.",
    alias: "invoiceNo",
    isShow: true,
    width: "180"
  },
  {
    id: 109,
    name: "Over Due Amount",
    alias: "dueAmount",
    isShow: true,
    width: "180"
  },
  {
    id: 110,
    name: "Remaining Settled Amount",
    alias: "remainingSettledAmount",
    isShow: true,
    width: "180"
  },
  {
    id: 111,
    name: "Over Days",
    alias: "overDueDays",
    textAlign: "center",
    isShow: true,
    width: "180"
  },
  {
    id: 112,
    name: "Over Due Days Count",
    alias: "overDueDaysCount",
    textAlign: "center",
    isShow: true,
    width: "200"
  },
  {
    id: 115,
    name: "Payment Status",
    alias: "paymentStatus",
    textAlign: "center",
    isShow: true,
    width: "200"
  },
  {
    id: 13,
    name: "Opening Debit Bal",
    alias: "openingDebitBalance",
    isShow: false
  },
  {
    id: 14,
    name: "Total Debit Bal",
    alias: "totalDebitBalance",
    isShow: false
  },
  {
    id: 15,
    name: "Opening Credit Bal",
    alias: "openingCreditBalance",
    isShow: false
  },
  {
    id: 16,
    name: "Total Credit Bal",
    alias: "totalCreditBalance",
    isShow: false
  },
  {
    id: 17,
    name: "Current Time Period Debit Bal",
    alias: "currentTimePeriodDebitBalance",
    isShow: false
  },
  {
    id: 18,
    name: "Current Time Period Credit Bal",
    alias: "currentTimePeriodCreditBalance",
    isShow: false
  },
  {
    id: 19,
    name: "Cash Opening Bal",
    alias: "cashOpeningBalance",
    isShow: false
  },
  { id: 20, name: "Cash", alias: "cash", isShow: false },
  {
    id: 21,
    name: "Cash Opening Debit Bal",
    alias: "cashOpeningDebitBalance",
    isShow: false
  },
  {
    id: 22,
    name: "Cash Closing Debit Bal",
    alias: "cashClosingDebitBalance",
    isShow: false
  },
  {
    id: 23,
    name: "Cash Opening Credit Bal",
    alias: "cashOpeningCreditBalance",
    isShow: false
  },
  {
    id: 24,
    name: "Cash Closing Credit Bal",
    alias: "cashClosingCreditBalance",
    isShow: false
  },
  {
    id: 25,
    name: "Cash Current Time Period Debit Bal",
    alias: "cashCurrentTimePeriodDebitBalance",
    isShow: false
  },
  {
    id: 26,
    name: "Cash Current Time Period Credit Bal",
    alias: "cashCurrentTimePeriodCreditBalance",
    isShow: false
  },
  {
    id: 27,
    name: "Igst Opening Bal",
    alias: "igstOpeningBalance",
    isShow: false
  },
  { id: 28, name: "Igst", alias: "igst", isShow: false },
  {
    id: 29,
    name: "Igst Opening Debit Bal",
    alias: "igstOpeningDebitBalance",
    isShow: false
  },
  {
    id: 30,
    name: "Igst Closing Debit Bal",
    alias: "igstClosingDebitBalance",
    isShow: false
  },
  {
    id: 31,
    name: "Igst Opening Credit Bal",
    alias: "igstOpeningCreditBalance",
    isShow: false
  },
  {
    id: 32,
    name: "Igst Closing Credit Bal",
    alias: "igstClosingCreditBalance",
    isShow: false
  },
  {
    id: 33,
    name: "Igst Current Time Period Debit Bal",
    alias: "igstCurrentTimePeriodDebitBalance",
    isShow: false
  },
  {
    id: 34,
    name: "Igst Current Time Period Credit Bal",
    alias: "igstCurrentTimePeriodCreditBalance",
    isShow: false
  },
  {
    id: 35,
    name: "Cgst Opening Bal",
    alias: "cgstOpeningBalance",
    isShow: false
  },
  { id: 36, name: "Cgst", alias: "cgst", isShow: false },
  {
    id: 37,
    name: "Cgst Opening Debit Bal",
    alias: "cgstOpeningDebitBalance",
    isShow: false
  },
  {
    id: 38,
    name: "Cgst Closing Debit Bal",
    alias: "cgstClosingDebitBalance",
    isShow: false
  },
  {
    id: 39,
    name: "Cgst Opening Credit Bal",
    alias: "cgstOpeningCreditBalance",
    isShow: false
  },
  {
    id: 40,
    name: "Cgst Closing Credit Bal",
    alias: "cgstClosingCreditBalance",
    isShow: false
  },
  {
    id: 41,
    name: "Cgst Current Time Period Debit Bal",
    alias: "cgstCurrentTimePeriodDebitBalance",
    isShow: false
  },
  {
    id: 42,
    name: "Cgst Current Time Period Credit Bal",
    alias: "cgstCurrentTimePeriodCreditBalance",
    isShow: false
  },
  {
    id: 43,
    name: "Sgst Opening Bal",
    alias: "sgstOpeningBalance",
    isShow: false
  },
  { id: 44, name: "Sgst", alias: "sgst", isShow: false },
  {
    id: 45,
    name: "Sgst Opening Debit Bal",
    alias: "sgstOpeningDebitBalance",
    isShow: false
  },
  {
    id: 46,
    name: "Sgst Closing Debit Bal",
    alias: "sgstClosingDebitBalance",
    isShow: false
  },
  {
    id: 47,
    name: "Sgst Opening Credit Bal",
    alias: "sgstOpeningCreditBalance",
    isShow: false
  },
  {
    id: 48,
    name: "Sgst Closing Credit Bal",
    alias: "sgstClosingCreditBalance",
    isShow: false
  },
  {
    id: 49,
    name: "Sgst Current Time Period Debit Bal",
    alias: "sgstCurrentTimePeriodDebitBalance",
    isShow: false
  },
  {
    id: 50,
    name: "Sgst Current Time Period Credit Bal",
    alias: "sgstCurrentTimePeriodCreditBalance",
    isShow: false
  },
  {
    id: 51,
    name: "Tds Opening Bal",
    alias: "tdsOpeningBalance",
    isShow: false
  },
  { id: 52, name: "Tds", alias: "tds", isShow: false },
  {
    id: 53,
    isShow: false,
    name: "Tds Opening Debit Bal",
    alias: "tdsOpeningDebitBalance"
  },
  {
    id: 54,
    isShow: false,
    name: "Tds Closing Debit Bal",
    alias: "tdsClosingDebitBalance"
  },
  {
    id: 55,
    isShow: false,
    name: "Tds Opening Credit Bal",
    alias: "tdsOpeningCreditBalance"
  },
  {
    id: 56,
    isShow: false,
    name: "Tds Closing Credit Bal",
    alias: "tdsClosingCreditBalance"
  },
  {
    id: 57,
    name: "Tds Current Time Period Debit Bal",
    alias: "tdsCurrentTimePeriodDebitBalance",
    isShow: false
  },
  {
    id: 58,
    name: "Tds Current Time Period Credit Bal",
    alias: "tdsCurrentTimePeriodCreditBalance",
    isShow: false
  },
  {
    id: 59,
    isShow: false,
    name: "Approved Opening Bal",
    alias: "approvedOpeningBalance"
  },
  { id: 60, isShow: false, name: "Approved Bal", alias: "approvedBalance" },
  {
    id: 61,
    isShow: false,
    name: "Approved Opening Debit Bal",
    alias: "approvedOpeningDebitBalance"
  },
  {
    id: 62,
    isShow: false,
    name: "Approved Closing Debit Bal",
    alias: "approvedClosingDebitBalance"
  },
  {
    id: 63,
    isShow: false,
    name: "Approved Opening Credit Bal",
    alias: "approvedOpeningCreditBalance"
  },
  {
    id: 64,
    isShow: false,
    name: "Approved Closing Credit Bal",
    alias: "approvedClosingCreditBalance"
  },
  {
    id: 65,
    isShow: false,
    name: "Approved Current Time Period Debit Bal",
    alias: "approvedCurrentTimePeriodDebitBalance"
  },
  {
    id: 66,
    isShow: false,
    name: "Approved Current Time Period Credit Bal",
    alias: "approvedCurrentTimePeriodCreditBalance"
  },
  {
    id: 67,
    isShow: false,
    name: "Approved Cash Opening Bal",
    alias: "approvedCashOpeningBalance"
  },
  { name: "Approved Cash Bal", alias: "approvedCashBalance" },
  {
    id: 68,
    isShow: false,
    name: "Approved Cash Opening Debit Bal",
    alias: "approvedCashOpeningDebitBalance"
  },
  {
    id: 69,
    isShow: false,
    name: "Approved Cash Closing Debit Bal",
    alias: "approvedCashClosingDebitBalance"
  },
  {
    id: 70,
    isShow: false,
    name: "Approved Cash Opening Credit Bal",
    alias: "approvedCashOpeningCreditBalance"
  },
  {
    id: 71,
    isShow: false,
    name: "Approved Cash Closing Credit Bal",
    alias: "approvedCashClosingCreditBalance"
  },
  {
    id: 72,
    isShow: false,
    name: "Approved Cash Current Time Period Debit Bal",
    alias: "approvedCashCurrentTimePeriodDebitBalance"
  },
  {
    id: 73,
    isShow: false,
    name: "Approved Cash Current Time Period Credit Bal",
    alias: "approvedCashCurrentTimePeriodCreditBalance"
  },
  {
    id: 74,
    isShow: false,
    name: "Approved Igst Opening Bal",
    alias: "approvedIgstOpeningBalance"
  },
  {
    id: 75,
    isShow: false,
    name: "Approved Igst Bal",
    alias: "approvedIgstBalance"
  },
  {
    id: 76,
    isShow: false,
    name: "Approved Igst Opening Debit Bal",
    alias: "approvedIgstOpeningDebitBalance"
  },
  {
    id: 78,
    isShow: false,
    name: "Approved Igst Closing Debit Bal",
    alias: "approvedIgstClosingDebitBalance"
  },
  {
    id: 79,
    isShow: false,
    name: "Approved Igst Opening Credit Bal",
    alias: "approvedIgstOpeningCreditBalance"
  },
  {
    id: 80,
    isShow: false,
    name: "Approved Igst Closing Credit Bal",
    alias: "approvedIgstClosingCreditBalance"
  },
  {
    id: 81,
    isShow: false,
    name: "Approved Igst Current Time Period Debit Bal",
    alias: "approvedIgstCurrentTimePeriodDebitBalance"
  },
  {
    id: 82,
    isShow: false,
    name: "Approved Igst Current Time Period Credit Bal",
    alias: "approvedIgstCurrentTimePeriodCreditBalance"
  },
  {
    id: 83,
    isShow: false,
    name: "Approved Cgst Opening Bal",
    alias: "approvedCgstOpeningBalance"
  },
  {
    id: 84,
    isShow: false,
    name: "Approved Cgst Bal",
    alias: "approvedCgstBalance"
  },
  {
    id: 85,
    isShow: false,
    name: "Approved Cgst Opening Debit Bal",
    alias: "approvedCgstOpeningDebitBalance"
  },
  {
    id: 86,
    isShow: false,
    name: "Approved Cgst Closing Debit Bal",
    alias: "approvedCgstClosingDebitBalance"
  },
  {
    id: 87,
    isShow: false,
    name: "Approved Cgst Opening Credit Bal",
    alias: "approvedCgstOpeningCreditBalance"
  },
  {
    id: 88,
    isShow: false,
    name: "Approved Cgst Closing Credit Bal",
    alias: "approvedCgstClosingCreditBalance"
  },
  {
    id: 89,
    isShow: false,
    name: "Approved Cgst Current Time Period Debit Bal",
    alias: "approvedCgstCurrentTimePeriodDebitBalance"
  },
  {
    id: 90,
    isShow: false,
    name: "Approved Cgst Current Time Period Credit Bal",
    alias: "approvedCgstCurrentTimePeriodCreditBalance"
  },
  {
    id: 91,
    isShow: false,
    name: "Approved Sgst Opening Bal",
    alias: "approvedSgstOpeningBalance"
  },
  {
    id: 92,
    isShow: false,
    name: "Approved Sgst Bal",
    alias: "approvedSgstBalance"
  },
  {
    id: 93,
    isShow: false,
    name: "Approved Sgst Opening Debit Bal",
    alias: "approvedSgstOpeningDebitBalance"
  },
  {
    id: 94,
    isShow: false,
    name: "Approved Sgst Closing Debit Bal",
    alias: "approvedSgstClosingDebitBalance"
  },
  {
    id: 95,
    isShow: false,
    name: "Approved Sgst Opening Credit Bal",
    alias: "approvedSgstOpeningCreditBalance"
  },
  {
    id: 96,
    isShow: false,
    name: "Approved Sgst Closing Credit Bal",
    alias: "approvedSgstClosingCreditBalance"
  },
  {
    id: 97,
    isShow: false,
    name: "Approved Sgst Current Time Period Debit Bal",
    alias: "approvedSgstCurrentTimePeriodDebitBalance"
  },
  {
    id: 98,
    isShow: false,
    name: "Approved Sgst Current Time Period Credit Bal",
    alias: "approvedSgstCurrentTimePeriodCreditBalance"
  },
  {
    id: 99,
    isShow: false,
    name: "Approved Tds Opening Bal",
    alias: "approvedTdsOpeningBalance"
  },
  {
    id: 100,
    isShow: false,
    name: "Approved Tds Bal",
    alias: "approvedTdsBalance"
  },
  {
    id: 101,
    isShow: false,
    name: "Approved Tds Opening Debit Bal",
    alias: "approvedTdsOpeningDebitBalance"
  },
  {
    id: 102,
    isShow: false,
    name: "Approved Tds Closing Debit Bal",
    alias: "approvedTdsClosingDebitBalance"
  },
  {
    id: 103,
    isShow: false,
    name: "Approved Tds Opening Credit Bal",
    alias: "approvedTdsOpeningCreditBalance"
  },
  {
    id: 104,
    isShow: false,
    name: "Approved Tds Closing Credit Bal",
    alias: "approvedTdsClosingCreditBalance"
  },
  {
    id: 105,
    isShow: false,
    name: "Approved Tds Current Time Period Debit Bal",
    alias: "approvedTdsCurrentTimePeriodDebitBalance"
  },
  {
    id: 106,
    isShow: false,
    name: "Approved Tds Current Time Period Credit Bal",
    alias: "approvedTdsCurrentTimePeriodCreditBalance"
  },
  {
    id: 117,
    isShow: false,
    name: "Account Code",
    alias: "accountCodeName",
    textAlign: "center",
  },
  {
    id: 118,
    isShow: false,
    name: "Transaction Type",
    alias: "accountTransactionTypeName",
    textAlign: "center",
  },
  {
    id: 114,
    isShow: true,
    name: "Trail",
    width: 150
  },
  {
    id: 107,
    isShow: true,
    name: "Remarks",
    width: 150
  },
  {
    id: 108,
    isShow: true,
    name: "Total Amount",
    minWidth: 220,
    textAlign: "end"
  }
];

// last - 118
