module.exports = {
  //============= Watsoo Accounts urls===========
  // loginUrl: "https://watsooaccounts.nyggs.com/loginUrl/",
  // identifierUrl: "https://watsooaccounts.nyggs.com/identifierUrl/",
  // baseUrl: "https://watsooaccounts.nyggs.com/baseUrl/",
  // permissionUrl: "https://watsooaccounts.nyggs.com/permissionUrl/",

  // ============manish bhai  local url============//
  
  //==================Accounts Demo Staging URL===============//
  // baseUrl: "http://192.168.60.193:8133/api/accounting/",
  // loginUrl: "https://accountsdemo.nyggs.com/loginUrl/",
  // identifierUrl: "https://accountsdemo.nyggs.com/identifierUrl/",
  // baseUrl: "https://accountsdemo.nyggs.com/baseUrl/",
  // permissionUrl: "https://accountsdemo.nyggs.com/permissionUrl/",

  // ==================Accounts SSO for demo URL================//
  // loginUrl: "https://demossoaccounts.nyggs.com/loginUrl/",
  // identifierUrl: "https://demossoaccounts.nyggs.com/identifierUrl/",
  // baseUrl: "https://demossoaccounts.nyggs.com/baseUrl/",
  // permissionUrl: "https://demossoaccounts.nyggs.com/permissionUrl/",

  //==================Accounts Production URL===============//
  loginUrl: "https://accounts.nyggs.com/loginUrl/",
  identifierUrl: "https://accounts.nyggs.com/identifierUrl/",
  baseUrl: "https://accounts.nyggs.com/baseUrl/",
  permissionUrl: "https://accounts.nyggs.com/permissionUrl/",

  siteUrl: "site/user_sites",
  headOfficeSiteUrl: "site/head_sites",

  // ==================Dashboard endpoints=============//
  receivableUrl: "dashboard/v1/get/total/receivable",
  payableUrl: "dashboard/v1/get/total/payable",
  expenseUrl: "dashboard/v1/get/income/expense",

  // ===================Account Groups endpoints================//
  accountGroupGetUrl: "account-group/v1/get",
  accountGroupDropdownUrl: "account-group/v1/get/drop-down",
  updateAccountGroupUrl: "account-group/v1/update",
  addAccountGroupUrl: "account-group/v1/add",
  accountGroupDropdownUrlV2: "account-group/v2/get/drop-down",
  accountGroupDropdownUrlVoucher: "account-group/v2/get/drop-down/for/voucher",
  accountGroupImport: "account-group/v1/import",
  accountGroupImportII: "account-group/v2/import",

  //   // ==============Report Groups Endpoint================//
  addReportGroupUrl: "account-group-reports/save/account/group/report",
  reportGroupListUrl: "account-group-reports/get/account/report/group/tree",
  reportGroupDropdownUrl:
    "account-group-reports/get/account/report/group/drop/down",

  // =============Accounts endpoints===================//
  accountTempImportUrl: "account/v1/register/import/account",
  accountManualImportUrl: "account/v1/account/from/group/import",
  accountBalanceImportUrl: "account/v1/account/opening/balance/import",
  singleAccountUrl: "account/v1/get",
  singleAccountUrlV2: "account/v1/get/account/for/drop/down",
  accountDropDownUrl: "account/v1/get/account/drop/down",
  singleAccountKycUrl: "account/v1/get/kyc-details",
  accountUpdateUrl: "account/v1/update",
  accountRegisterUrl: "account/v1/register",
  accountCustomFieldsUrl: "custom_field/v1/get",
  accountImportUrl: "account/v1/import",
  accountKycSaveUrl: "account/v1/kyc/update",
  accountConfigUrl: "account-company-config/v1/get",
  saveAccountConfigUrl: "account-company-config/v1/update",
  filterAccountUrl: "account/v1/get/drop-down",
  accountManualImport: "account-transaction/v1/import/manual",
  accountCodeGet: "account-code/get",
  accountCodeSave: "account-code/save",

  // ================Account Types & custom fields endpoints=================//
  accountTypesV1Url: "account-type/v1/get",
  accountTypesAddUrl: "account-type/v1/add",
  accountTypesUpdateUrl: "account-type/v1/update",
  accountTypesV2Url: "account-type/v2/get",
  accountTypesImportUrl: "account-type/v1/import",
  customFieldsUpdateUrl: "custom_field/v1/update",
  customFieldsTypesUrl: "custom_field/v1/get/data-type",
  customFieldsAddUrl: "custom_field/v1/add",
  customFieldsRegexUrl: "custom_field/v1/get/regex",
  accountTypesMasterUrl: "account-type/v1/get/type-master",

  // ================Transaction endpoints======================//
  addTransactionUrl: "account-transaction/v1/add",
  addInterSiteTransactionUrl: "account-transaction/v1/inter-site/add",
  bulkAddAccountTransaction: "account-transaction/add/bulk",
  updateTransactionUrl: "account-transaction/v1/update",
  transactionsUrl: "account-transaction/v2/get",
  transactionsUrlV2: "account-transaction/v1/get/with/voucher/detail",
  transactionTypesUrl: "account-transaction/v1/get/type",
  transactionRevertUrl: "account-transaction/v1/revert",
  transactionImportUrl: "account-transaction/v1/import",
  transactionHistoryUrl: "account-transaction/v1/get/history",
  transactionLedgerExportUrl: "report/v1/pdf/export/transaction-ledger",
  singleTransactionGetUrl: "account-transaction/v1/get-by-account-id",
  importTransactionData: "account-transaction/v3/import",
  getAccountCode: "account-code/get",
  getTransactionType: "account-transaction-type/get",
  getInvoiceTransactionList: "account-transaction/v1/get/invoices",
  accountTransactionTypeGet: "account-transaction-type/get",
  accountTransactionTypeSave: "account-transaction-type/save",

  // ======================Trial Balance endpoints=================//
  accountTrialBalanceUrl: "account-transaction/v1/account/group/tree/list",
  trialBalanceUrl: "report/v1/get/trial-balance",
  trialBalanceExportUrl: "report/v1/pdf/export/trial-balance",

  // =============Entity Template endpoints===============//
  getEntityTempUrl: "entity-template/v1/get",
  entityTemplateUpdate:
    "entity-template/v1/account-entity-variable-mapping/update",

  // ==================vendors endpoints================//
  companyVendorsUrl: "vendor/v1/get/company/platform/vendor",
  saveVendorUrl: "vendor/v1/save/vendor/template/map",
  vendorDetailList: "vendor/v1/get/company/vendor/details",
  configApiList: "vendor/v1/get/company/vendor/api",

  // =================Assests endpoints================//
  assetsUrl: "account-transaction/v1/group/transaction/get",
  // =============Financial Year endpoints===========//
  financialYearsUrl: "financial-year/v1/get",
  financialYearMonthUrl: "financial-year/v1/get/financial/year/month/5",

  // =================Files endpoints================//
  // fileUrl: "hrm_master/files",
  fileUrl: "file-upload/v1/get/file/url",

  // =============reports endpoints========//
  balanceSheetUrl: `report/v1/get/balanceSheet/`,
  plUrl: `report/v1/get/p-and-l/`,
  cashflowUrl: `report/v1/get/cash-flow/`,
  generateReports: `report/v1/get/generateReport`,
  gstReportUrl: "report/v1/get/gstReport/",
  tdsReportUrl: "report/v1/get/tdsReport/",

  // ==========vouchers endpoints===========//
  voucherListUrl: "voucher/type/master/get/customForm/type/bycompanyId",
  voucherTypesUrl: "voucher/type/master/v2/get/customForm/type",
  voucherTypesUrlV1: "voucher/type/master/v1/get/customForm/type/for/drop/down",
  voucherTypeSaveUrl: "voucher/type/master/save/templateType/data",
  voucherUdpateUrl: "voucher/master/save/templateMaster/data",
  singleVoucherGetUrl: `voucher/master/get/template/data`,
  voucherConfigGetUrl: "thirdParty/account/key-mapping/get",
  voucherConfigSaveUrl: "thirdParty/account/key-mapping/add",
  transactionVoucher: "voucher/type/master/v1/approved/get/customForm/type",
  tempAccountGet: "voucher/master/get/temp/account",

  // ===============user permission user===============//
  // http://localhost:8080/api/role-permission-mapping/get/roleId

  //  ==============Reports Management================= //
  reportsListUrl: "get/company/report",
  createReport: "create/report",
  updateReport: "update/report",
  groupList: "report/groups",
  createGroup: "create/company/report/group",
  groupListById: "company/report",
  deleteGrouprthrough: "update/company/report/group",
  getReports:
    "account-transaction/v1/account/group/tree/list/for/generic/report",

  // ===========Permission Urls ====================//

  // ===========Permission Urls ====================//
  userPermissionUrl: "role-permission-mapping/get/roleId",
  permissionCombinationUrl: "permission-state-role-mapping/get/all",
  getAllState: "permission-state/get/all",
  bulkChangeState: "account/v1/bulk/change-state",
  bulkAccountGroupState: "account-group/v1/bulk/change-state",

  // ===================Payment request Url==============================
  getAllRequest: "payment_request/v1/get/all",
  addPaymentRequest: "payment_request/v1/add",
  updatePaymentRequest: "payment_request/v1/updated",

  // ================Bank reconciliation==============//
  importBankReconciliationUrl: "bank-reconciliation/v1/import/bank-statement",
  // =================Notification=============================
  getNotification: "api/notifications/getAll/userId",

  importBankReconciliationUrl: "bank-reconciliation/v1/import/bank-statement",

  reportChildListUrl:
    "account-transaction/v1/account/group/tree/list/by/group/id",

  // =================Account Configuration===================
  accountsConfigGet: "account/v1/accounts/management",
  accountConfigUpdate: "account/v1/enable/site/account",
  accountsForTransactionURL: "account/v1/get/account/drop/down",

  // =================Narration Configuration================
  narrationImportUrl: "narration-masters/v1/import",
  narrationGet: "narration-masters/v1/get",
  narrationSave: "narration-masters/v1/save",

  // ==============dayBook=============
  selectedTransactionIdsUrl: "account-transaction/v1/approved/transaction",

  // ======== Bank Book ===========
  bankBookReportUrl: "account-group-reports/get/account/group/report",

  // ======== Company config ===========
  companyConfigSave: "company-config/save",
  companyConfigUpdate: "company-config/update",
  companyConfigMasterList: "company-config/get/all/master/config",
  companyConfigGetAll: "company-config/get-by-company/1",
  companyConfigGet: "get/company/config/by/master/config",
  
};
