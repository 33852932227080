import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Collapse,
  Box,
  IconButton,
  Typography,
  TableCell,
  TableRow,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import { cellColor, themecolor } from "../Navigation";
import { withStyles } from "@material-ui/core/styles";
// import { TableRow, StyledTableCell } from '../utils';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AmountInfo from "../CommonComps/AmountInfo";
import ColorInfo from "../CommonComps/ColorInfo";
import { userService } from "../userService";
import { reportChildListUrl, trialBalanceUrl } from "../config";
import { getEncryptPayload } from "../Network_Utility";
import { updateNestedItem, zeroBalanceReportFilter } from "../utils";
import { AutoSizer, List, InfiniteLoader } from "react-virtualized";
import { TbWorldSearch } from "react-icons/tb";
import MySwitch from "./Switch";
import { Editedtext } from "./TextTemplate";

// const backgroundColors = ['#673771', '#382c5f', '#335120', '#d1685f'];
const backgroundColors = ["#7AADED", "#DCF2FF"];

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: ` ${themecolor}`,
    padding: "8px 5px!important",
    // color: "white !important",
    fontSize: "14px !important",
    width: "fit-content",
  },
  body: {
    fontSize: "14px !important",
    fontWeight: 400,
    padding: "0px !important",
    color: "#555",
    // width: "300px !important",
  },
}))(TableCell);

const checkRow = (dt, rowName) => {
  for (let i = 0; i < dt.length; i++) {
    if (dt[i]?.name == rowName || dt[i]?.accountName == rowName) {
      // console.log("dat[i]",dt[i])
      return dt[i];
    } else if (dt[i]?.childList && dt[i]?.childList.length > 0) {
      const result = checkRow(dt[i].childList, rowName);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

const TableCellWithData = ({
  siteData,
  rowName,
  field,
  siteIndex,
  filteredColumns,
}) => {
  const dt = [siteData];
  const res = checkRow(dt, rowName);
  return (
    <StyledTableCell
      style={{
        minWidth: "300px",
        fontSize: "14px !important",
        border: res?.accountCode && "1px solid white",
        background: res?.accountCode
          ? "#a269b3"
          : siteIndex
          ? backgroundColors[siteIndex % backgroundColors.length]
          : "#7AADED",
        color: res?.accountCode
          ? "white"
          : field &&
            (field.includes("openingDebitBalance") ||
              field.includes("accountGroupDebitBalance") ||
              field.includes("currentMonthDebitBalance"))
          ? "green"
          : field &&
            (field.includes("openingCreditBalance") ||
              field.includes("accountGroupCreditBalance") ||
              field.includes("currentMonthCreditBalance"))
          ? "red"
          : res && res[field] == 0
          ? "black"
          : res && res[field] < 0
          ? "green"
          : res && res[field] > 0
          ? "red"
          : backgroundColors[siteIndex % backgroundColors.length] == "#DCF2FF"
          ? "#6e5555"
          : "#fff",
        textAlign: "right",
      }}
    >
      <div>
        {res && res[field] ? <AmountInfo amount={res[field]} /> : "-"}
        {/* {field} */}

        <span>
          {field &&
            (field === "openingBalance" ||
              field === "accountGroupCurrentBalance") &&
            (res && res[field] < 0
              ? " Dr."
              : res && res[field] > 0
              ? " Cr."
              : "")}
        </span>
      </div>
    </StyledTableCell>
  );
};

const Row = ({
  row,
  index,
  siteList,
  siteDataList,
  filteredColumns,
  level = 0,
  setLoading,
  tableData,
  setData,
  setReportsListCopied,
  errorAlert,
  successAlert,
  fromDate,
  toDate,
  monthId,
  isSitewiseList,
  openDefault,
  columnConfig,
  isBalanceWiseData,
}) => {
  const { childList, name } = row; // Destructure siteDataList from the row
  const [open, setOpen] = React.useState(false);

  const [rowData, setRowData] = useState([]);
  const [isAccount, setIsAccount] = React.useState(false);
  const [accountSerchValue, setAccountSearchValue] = React.useState("");
  const [pageNum, setPageNum] = React.useState(0);
  const isLeafNode = !childList || childList.length == 0;
  const [balanceStatus, setBalanceStatus] = useState(true);
  const [infiniteLoaderKey, setInfiniteLoaderKey] = useState(0);

  const handleClickIcon = (row) => {
    if (!open) {
      if (!row.childList || row.childList.length == 0) {
        makeAPICallOnLeafNode(row);
        // const permissionExist = checkPermissionExist(endPoints, trialBalanceUrl)
        // if (permissionExist)

        // else errorAlert(`Unautharized to access accounts trial balance of ${row.name} group.`)
      } else setOpen(!open);
      // Set open to true before making the API call
    } else setOpen(!open); // Toggle open state
  };

  const makeAPICallOnLeafNode = async (row) => {
    setLoading(true);
    const payload = {
      reportId: 5,
      groupId: row?.id,
      siteId: null,
      fromDate,
      toDate,
      monthId,
    };

    const encryptedPayload = getEncryptPayload(payload, true);

    const apiUrl = reportChildListUrl;

    try {
      setLoading(true);
      const response = await userService.postData(
        apiUrl,
        encryptedPayload,
        null,
        true
      );

      if (response && response.data && response.data.status == 200) {
        setLoading(false);
        if (
          response?.data?.data &&
          response?.data?.data.length > 0 &&
          response?.data?.data[0]?.childList?.length > 0
        ) {
          const updatedRow = {
            ...row,
            childList: response?.data?.data[0]?.childList,
          };
          const balanceFilterRow = {
            ...row,
            childList: zeroBalanceReportFilter(
              response?.data?.data[0]?.childList
            ),
          };

          if (isBalanceWiseData) {
            setRowData(balanceFilterRow);
            if (balanceFilterRow?.childList?.length > 0) {
              setOpen(true);
            } else {
              setOpen(false);
              errorAlert("There are no accounts that have a balance!");
            }
          } else {
            setRowData(updatedRow);
            if (updatedRow?.childList?.length > 0) {
              setOpen(true);
            } else {
              setOpen(false);
            }
          }

          const updatedData = updateNestedItem(tableData, updatedRow);
          setData(updatedData);
          setReportsListCopied(updatedData);
        } else getAccountsList(row, 1, false, balanceStatus);
      } else {
        setLoading(false);
        // Handle API error
        errorAlert(response?.data?.message);
        console.error("API Error:", response.data.message);
      }
    } catch (error) {
      setLoading(false);
      // Handle API call error
      console.error("API Call Error:", error);
    } finally {
      // setLoading(false);
    }
  };

  const getAccountsList = async (
    row,
    pageN,
    isSearch,
    balanceStatus,
    fetch
  ) => {
    const payload = {
      fromDate,
      toDate,
      monthId,
      siteId: null,
      accountGroupIds: [row?.id],
      tokenData: {
        companyId: "11",
        moduleId: null,
        organizationId: null,
        userCode: null,
        userId: "14000",
        userName: null,
        userRoleId: null,
      },
      financialYearId: 5,
      pageNo: pageN || 1,
      pageSize: 20,
      searchKey: accountSerchValue
        ? accountSerchValue?.trim().replace(/\s+/g, " ")
        : "",
      isHavingTransaction: balanceStatus,
    };

    const apiUrl = trialBalanceUrl;

    try {
      pageN === 1 && setLoading(true);
      const response = await userService.postData(apiUrl, payload);

      if (response && response.data && response.data.status === 200) {
        pageN === 1 && setLoading(false);
        const { accountInfoList } = response?.data?.data?.data;

        if (
          accountInfoList !== null &&
          accountInfoList &&
          accountInfoList.length > 0
        ) {
          setIsAccount(true);
          const newList = accountInfoList.map((item) => ({
            ...item,
            accountId: item?.accountId,
            name: item.accountName,

            isAccount: true,
            childList: [], // Make sure to set childList as an empty array
          }));
          // const updatedRow = { ...row, childList: newList };
          const updatedRow =
            rowData?.childList && !isSearch && !fetch
              ? { ...row, childList: [...rowData?.childList, ...newList] }
              : { ...row, childList: newList };
          setRowData(updatedRow);

          // Update the main data array with the updated row
          const updatedData = updateNestedItem(tableData, updatedRow);
          // setData(updatedData);
          // setReportsListCopied(updatedData);

          // Toggle open state
          setOpen(true);
        } else {
          if (pageN === 1) {
            const updatedRow = { ...row, childList: [] };
            setRowData(updatedRow);
            setLoading(false);
            successAlert(`No Account available for ${row.name}`);
          }
          // If there are no child items, just toggle open state

          if (rowData.childList) {
            if (rowData.childList.length === 0) {
              successAlert(`No Account available for ${row.name}`);
            }
          } else {
            successAlert(`No Account available for ${row.name}`);
          }
        }
      } else {
        pageN === 1 && setLoading(false);
        // Handle API error
        errorAlert(response?.data?.message);
        console.error("API Error:", response.data.message);
      }
    } catch (error) {
      if (rowData.childList) {
        if (rowData.childList.length === 0) {
          successAlert(`No Account available for ${row.name}`);
        }
      } else {
        successAlert(`No Account available for ${row.name}`);
      }
      // Handle API call error
      pageN === 1 && setLoading(false);
      console.error("API Call Error:", error);
      //   setLoading(false)
    } finally {
    }
  };

  const renderTableCells = (data, rowName, filteredColumns, rowData) => {
    return (
      <React.Fragment>
        {siteList && siteList.length > 0 ? (
          siteList.map((site, siteIndex) => (
            <>
              {filteredColumns &&
              Array.isArray(filteredColumns) &&
              filteredColumns.length > 0
                ? filteredColumns.map(
                    (column, columnIndex) =>
                      site.isShow &&
                      column.isShow && (
                        <TableCellWithData
                          key={`site-${siteIndex}-column-${columnIndex}`}
                          siteData={
                            data && data.find((val) => val.siteId == site.id)
                          }
                          rowName={rowName}
                          field={column.alias}
                          siteIndex={siteIndex}
                          filteredColumns={filteredColumns}
                        />
                      )
                  )
                : site.isShow && (
                    <TableCellWithData
                      key={`site-${siteIndex}-column-1`}
                      siteData={
                        data && data.find((val) => val.siteId === site.id)
                      }
                      rowName={rowName}
                      siteIndex={siteIndex}
                    />
                  )}
            </>
          ))
        ) : (
          <>
            {filteredColumns &&
              Array.isArray(filteredColumns) &&
              filteredColumns.length > 0 &&
              filteredColumns.map(
                (column, columnIndex) =>
                  column.isShow && (
                    <TableCellWithData
                      key={`site-column-${columnIndex}`}
                      siteData={rowData}
                      rowName={rowName}
                      field={column.alias}
                      // siteIndex={siteIndex}
                      filteredColumns={filteredColumns}
                    />
                  )
              )}
          </>
        )}
      </React.Fragment>
    );
  };

  const resetInfiniteLoader = () => {
    setInfiniteLoaderKey((prevKey) => prevKey + 1); // Increment the key to force re-render
    setPageNum(1);
  };
  const isAccountLoaded = ({ index }) => {
    if (!isAccount) return;
    const loadedItem = !!rowData.childList[index];
    return loadedItem;
  };

  const loadMoreRows = ({ startIndex, stopIndex }) => {
    let quotient = Math.ceil(stopIndex / 20);
    const pageSize = quotient + 1;
    console.log(pageSize, quotient);
    if (pageNum === pageSize) {
      return;
    }
    setPageNum(pageSize);
    getAccountsList(row, pageSize, false, balanceStatus);
  };

  const searchAccountHandle = () => {
    setTimeout(() => {
      resetInfiniteLoader();
    }, 500);
    getAccountsList(row, 1, true, balanceStatus);
  };

  const renderChildRows = (childList, isSitewiseList) =>
    isAccount && childList ? (
      <Box>
        <Grid container alignItems={"center"} spacing={3}>
          <Grid item sx={{ paddingLeft: "9px" }}>
            <TextField
              value={accountSerchValue}
              onChange={(e) => setAccountSearchValue(e.target.value)}
              margin="dense"
              size="small"
              style={{
                maxWidth: 250,
              }}
              label="Search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={searchAccountHandle}
                    >
                      <TbWorldSearch />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <MySwitch
              checked={balanceStatus}
              onChange={(e) => {
                setBalanceStatus(e.target.checked);
                setTimeout(() => {
                  resetInfiniteLoader();
                }, 500);
                getAccountsList(
                  row,
                  1,
                  Boolean(accountSerchValue?.trim()),
                  e.target.checked,
                  true
                );
              }}
              label={!balanceStatus ? "Balance On" : "Balance Off"}
            />
          </Grid>
        </Grid>
        <AutoSizer style={{ height: "500px" }}>
          {({ height, width }) => {
            const itemsPerRow = 1;

            return (
              <InfiniteLoader
                key={infiniteLoaderKey}
                isRowLoaded={isAccountLoaded}
                loadMoreRows={loadMoreRows}
                rowCount={Math.ceil(childList?.length / itemsPerRow) + 1}
              >
                {({ onRowsRendered, registerChild }) => (
                  <List
                    onRowsRendered={onRowsRendered}
                    ref={registerChild}
                    width={width}
                    height={500}
                    rowHeight={50}
                    rowCount={Math.ceil(childList?.length / itemsPerRow)}
                    overscanRowCount={3}
                    rowRenderer={({ index, key, style }) => {
                      const startIndex = index * itemsPerRow;
                      const endIndex = Math.min(
                        startIndex + itemsPerRow,
                        childList?.length
                      );
                      const items = childList
                        ?.slice(startIndex, endIndex)
                        .map((val, ind) => (
                          <div>
                            <Row
                              key={`child-${index}`}
                              row={val}
                              index={`${index + 1}`}
                              siteList={siteList}
                              siteDataList={val?.siteDataList}
                              filteredColumns={filteredColumns}
                              level={level + 1}
                              setLoading={setLoading}
                              tableData={tableData}
                              setData={setData}
                              errorAlert={errorAlert}
                              successAlert={successAlert}
                              fromDate={fromDate}
                              toDate={toDate}
                              monthId={monthId}
                              isSitewiseList={isSitewiseList}
                              columnConfig={columnConfig}
                              isBalanceWiseData={isBalanceWiseData}
                              // Pass the child's specific siteDataList or use the parent's
                            />
                          </div>
                        ));

                      return (
                        <div key={key} style={style}>
                          {items}
                        </div>
                      );
                    }}
                  />
                )}
              </InfiniteLoader>
            );
          }}
        </AutoSizer>
      </Box>
    ) : (
      childList &&
      childList.length > 0 &&
      childList.map((child, childIndex) => (
        <Row
          key={`child-${childIndex}`}
          row={child}
          // index={childIndex}
          index={`${index}.${childIndex + 1}`}
          siteList={siteList}
          siteDataList={child?.siteDataList}
          filteredColumns={filteredColumns}
          level={level + 1}
          setLoading={setLoading}
          tableData={tableData}
          setData={setData}
          errorAlert={errorAlert}
          successAlert={successAlert}
          fromDate={fromDate}
          toDate={toDate}
          monthId={monthId}
          isSitewiseList={isSitewiseList}
          columnConfig={columnConfig}
          isBalanceWiseData={isBalanceWiseData}
          // Pass the child's specific siteDataList or use the parent's
        />
      ))
    );

  useEffect(() => {
    if (openDefault) {
      if (openDefault?.level >= 0 && openDefault.open) {
        handleClickIcon(row);
      }
    }
    row && setRowData(row);
  }, [row]);

  return (
    <React.Fragment>
      <TableRow
        style={{ cursor: "pointer", padding: "0px !important" }}
        onClick={() => setOpen(!open)}
      >
        {rowData && !rowData?.isAccount ? (
          <StyledTableCell
            style={{
              padding: 0,
              minWidth: "150px",
            }}
          >
            <Typography variant="body2" className="table_value">
              {index}
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => handleClickIcon(rowData)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Typography>
          </StyledTableCell>
        ) : (
          <StyledTableCell
            style={{
              padding: 0,
              minWidth: "150px",
            }}
          >
            <Typography variant="body2" className="table_value">
              {" "}
              {index}
              <IconButton disabled>
                <KeyboardArrowUpIcon style={{ visibility: "hidden" }} />
              </IconButton>
            </Typography>
          </StyledTableCell>
        )}
        <StyledTableCell
          style={{ minWidth: 350, color: rowData?.isAccount && "blue" }}
        >
          <Editedtext num={50} text={rowData?.name || rowData?.accountName} />
          {/* {rowData?.name || rowData?.accountName} */}
        </StyledTableCell>

        {isSitewiseList
          ? renderTableCells(
              siteDataList || rowData?.siteAccountList,
              rowData?.name || rowData?.accountName,
              // filteredColumns,
              columnConfig,
              rowData
            )
          : renderTableCells(
              siteDataList || rowData?.siteAccountList,
              rowData?.name || rowData?.accountName,
              columnConfig
              // filteredColumns,
            )}
      </TableRow>

      <StyledTableCell
        colSpan={filteredColumns && filteredColumns.length > 0 ? 340 : 340}
        sx={{ borderBottom: "none" }}
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box>
            <TableContainer>
              <Table style={{ borderCollapse: "collapse" }}>
                <TableBody>
                  {isSitewiseList
                    ? renderChildRows(rowData?.childList, isSitewiseList)
                    : renderChildRows(rowData?.childList)}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Collapse>
      </StyledTableCell>
    </React.Fragment>
  );
};

const SiteWiseTable = ({
  data,
  siteList,
  pathname,
  setLoading,
  setData,
  setReportsListCopied,
  errorAlert,
  successAlert,
  fromDate,
  toDate,
  monthId,
  mainData,
  openDefault,
  columnConfig,
  isBalanceWiseData,
}) => {
  const siteListHeader =
    siteList &&
    siteList.length > 0 &&
    siteList.filter((val) => val.isShow === true);

  return (
    <>
      <TableContainer
        component={Paper}
        style={{
          marginTop: 20,
          height: data && data.length > 0 && "calc(100vh - 245px)",
        }}
        id="cashflow_table_container"
      >
        <Table style={{ borderCollapse: "collapse" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell
                rowSpan={2}
                style={{ minWidth: 150, color: "#fff" }}
              >
                S.No.
              </StyledTableCell>
              <StyledTableCell
                rowSpan={2}
                style={{ minWidth: 350, color: "#fff" }}
              >
                Name
              </StyledTableCell>

              {siteListHeader &&
                siteListHeader.length > 0 &&
                siteListHeader.map((site, siteIndex) => {
                  return (
                    site.isShow && (
                      <StyledTableCell
                        id={`scroll_id_${site.id}`}
                        colSpan={
                          columnConfig &&
                          columnConfig?.filter((ele) => ele.isShow === true)
                            .length
                        }
                        key={`site-header-${siteIndex}`}
                        className={`site-header-${siteIndex}`}
                        align="center"
                        style={{
                          background:
                            site.isShow &&
                            backgroundColors[
                              siteIndex % backgroundColors.length
                            ],
                          // color: backgroundColors[siteIndex % backgroundColors.length] == "#DCF2FF" ? "#6e5555" : "#fff",
                          color: "#070758",
                          // minWidth: 220,
                        }}
                      >
                        {site.name.toUpperCase()}
                      </StyledTableCell>
                    )
                  );
                })}

              {siteListHeader.length === 0 && (
                <StyledTableCell
                  colSpan={
                    columnConfig &&
                    columnConfig?.filter((ele) => ele.isShow === true).length
                  }
                  key={`site-header-all`}
                  align="center"
                  style={{
                    background: backgroundColors[8 % backgroundColors.length],
                    // color:
                    //   backgroundColors[8 % backgroundColors.length] ===
                    //   "#DCF2FF"
                    //     ? "#6e5555"
                    //     : "#fff"
                    color: "#070758",
                  }}
                >
                  Total Sites
                </StyledTableCell>
              )}
            </TableRow>

            <TableRow>
              {siteListHeader &&
                siteListHeader.length > 0 &&
                siteListHeader.map(
                  (site, siteIndex) =>
                    columnConfig &&
                    columnConfig.length > 0 &&
                    columnConfig.map(
                      (column, columnIndex) =>
                        site.isShow &&
                        column.isShow && (
                          <React.Fragment
                            key={`site-header-${siteIndex}-column-${columnIndex}`}
                          >
                            <StyledTableCell
                              align="right"
                              style={{
                                minWidth: 220,
                                fontSize: "14px",
                                background:
                                  backgroundColors[
                                    siteIndex % backgroundColors.length
                                  ],
                                color:
                                  backgroundColors[
                                    siteIndex % backgroundColors.length
                                  ] == "#DCF2FF"
                                    ? "#6e5555"
                                    : "#fff",
                              }}
                            >
                              {column.name}
                            </StyledTableCell>
                          </React.Fragment>
                        )
                    )
                )}

              {siteListHeader.length == 0 &&
                columnConfig &&
                columnConfig.length > 0 &&
                columnConfig.map(
                  (column, columnIndex) =>
                    column.isShow && (
                      <React.Fragment
                        key={`site-header-${column.id}-column-${columnIndex}`}
                      >
                        <StyledTableCell
                          align="right"
                          style={{
                            minWidth: 220,
                            fontSize: "14px",
                            background:
                              backgroundColors[8 % backgroundColors.length],
                            color:
                              backgroundColors[8 % backgroundColors.length] ===
                              "#DCF2FF"
                                ? "#6e5555"
                                : "#fff",
                          }}
                        >
                          {column.name}
                        </StyledTableCell>
                      </React.Fragment>
                    )
                )}
            </TableRow>
          </TableHead>

          <TableBody>
            {siteListHeader?.length === 0
              ? data.map((row, index) => (
                  <Row
                    columnConfig={columnConfig}
                    openDefault={openDefault}
                    key={`row-${index}`}
                    row={row}
                    index={index + 1}
                    siteList={siteListHeader}
                    siteDataList={
                      row.siteDataList && row.siteDataList.length > 0
                        ? row.siteDataList
                        : row?.siteAccountList && row.siteAccountList.length > 0
                        ? row.siteAccountList
                        : []
                    }
                    filteredColumns={[]}
                    setLoading={setLoading}
                    tableData={mainData}
                    setData={setData}
                    setReportsListCopied={setReportsListCopied}
                    errorAlert={errorAlert}
                    successAlert={successAlert}
                    fromDate={fromDate}
                    toDate={toDate}
                    monthId={monthId}
                    isSitewiseList={true}
                    isBalanceWiseData={isBalanceWiseData}
                  />
                ))
              : data.map((row, index) => (
                  <Row
                    columnConfig={columnConfig}
                    openDefault={openDefault}
                    key={`row-${index}`}
                    row={row}
                    index={index + 1}
                    siteList={siteListHeader}
                    siteDataList={
                      row.siteDataList && row.siteDataList.length > 0
                        ? row.siteDataList
                        : row?.siteAccountList && row.siteAccountList.length > 0
                        ? row.siteAccountList
                        : []
                    }
                    filteredColumns={[]}
                    setLoading={setLoading}
                    tableData={mainData}
                    setData={setData}
                    setReportsListCopied={setReportsListCopied}
                    errorAlert={errorAlert}
                    successAlert={successAlert}
                    fromDate={fromDate}
                    toDate={toDate}
                    monthId={monthId}
                    isSitewiseList={false}
                    isBalanceWiseData={isBalanceWiseData}
                  />
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data && data.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 10,
          }}
        >
          <ColorInfo />
        </div>
      )}
    </>
  );
};

export default SiteWiseTable;
