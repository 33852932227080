import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Paper,
  Container,
  Grid,
  IconButton,
  Drawer,
  Stack,
  Autocomplete,
  TextField,
  FormControlLabel,
  Switch,
  InputAdornment,
  Badge,
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { userService } from "../userService";
import { AlertBar } from "../Alert/AlertBar";
import { getEncryptPayload } from "../Network_Utility";
import ReportsTable from "./ReportsTable";
import LoadingComponent from "../Loader";
import Header from "../CommonComps/Header";
import { useDispatch } from "react-redux";
import { styles } from "../Theme/Theme";
import { AssetsAction } from "../features/actions/AssetsAction";
import SideBar from "../CommonComps/SideBar";
import MenuIcon from "@mui/icons-material/Menu";
import TuneIcon from "@mui/icons-material/Tune";
import moment from "moment";
import { checkPathExist, checkPermissionExist } from "../utils";
import {
  accountTrialBalanceUrl,
  singleAccountUrl,
  trialBalanceUrl,
} from "../config";
import { getAllAccounts } from "../features/actions/AccountAction";
import { useLocation, useNavigate } from "react-router-dom";
import { renderChip } from "../CommonComps/ChipComponent";
import SiteWiseTable from "../CommonComps/SiteWiseTable";
import AutoDialogBox from "../CommonComps/AutoDialogBox";
import { FcSearch } from "react-icons/fc";
import { TbWorldSearch } from "react-icons/tb";
import { userConfig } from "../config/UserConfig";
const report = require("../images/report.png");

const Index = ({ classes }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const reduxState = useSelector((state) => state?.rootReducer);
  const accountsList = reduxState?.accountReducer?.data || [];
  // const siteList = reduxState?.sitesReducer?.data

  const siteList = [
    { id: 909, name: "Select All", alias: "all" },
    { id: 910, name: "Remove All", alias: "remove" },
    ...reduxState?.sitesReducer?.data,
  ];

  const [loading, setLoading] = useState(false);
  const [reportsList, setReportsList] = useState([]);
  const [site, setSite] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [getSiteWiseData, setGetSiteWiseData] = useState(false);
  // ===========Alerts===================//
  const [alerts, setAlerts] = useState({
    visible: false,
    alertMessage: "",
    alertType: "",
  });

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [selectedmonth, setSelectedmonth] = useState(null);
  const [siteName, setSiteName] = useState([]);

  const endPoints = reduxState.permissionReducer?.data;

  const monthsNameArray = [
    { id: 10, name: "January", type: "january" },
    { id: 11, name: "February", type: "february" },
    { id: 12, name: "March", type: "march" },
    { id: 1, name: "April", type: "april" },
    { id: 2, name: "May", type: "may" },
    { id: 3, name: "June", type: "june" },
    { id: 4, name: "July", type: "july" },
    { id: 5, name: "August", type: "august" },
    { id: 6, name: "September", type: "september" },
    { id: 7, name: "October", type: "october" },
    { id: 8, name: "November", type: "november" },
    { id: 9, name: "December", type: "december" },
  ];

  //==================Pagination====================//
  const [pageNo, setPageNo] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(15);
  const [totalPages, setTotalPages] = React.useState(1);
  const [showFilteredValues, setShowFilteredValues] = useState(false);
  const [selectedSites, setSelectedSites] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [searchField, setSearchField] = React.useState(null);
  const [searchStatus, setSearchStatus] = useState(false);

  const [reportsListCopied, setReportsListCopied] = useState([]);
  const [openDefault, setOpenDefault] = useState({});

  const [isBalanceWiseData, setIsBalanceWiseData] = useState(true)

  const restoredSite = useSelector(
    (site) => site?.rootReducer?.siteReducer?.site
  );

  let financialYearMonth = reduxState?.fymReducer?.data || [];

  // Function to close alert bar
  const closeAlertBar = () => setAlerts({ ...alerts, visible: false });

  // Function to show success alert
  const successAlert = (message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Data fetched succesfully",
      alertType: "success",
    });
    setLoading(false);
  };

  // Function to show error
  const errorAlert = (message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Something went wrong",
      alertType: "error",
    });
    setLoading(false);
  };

  // array of filteredValues to pass as props in the SideBar COmponents
  const filteredValues = [
    {
      typeId: 3,
      label: "From Date",
      value: fromDate,
      style: { width: 200, margin: "0 15px" },
      function: (value) => handleFromDate(value),
      isDisabled: selectedmonth?.id ? true : false,
    },
    {
      typeId: 3,
      label: "To Date",
      value: toDate,
      style: { width: 200, margin: "0 15px" },
      function: (value) => handleToDate(value),
      isDisabled: selectedmonth?.id ? true : false,
    },

    {
      typeId: 2,
      label: "Month",
      options: financialYearMonth || [],
      value: selectedmonth,
      style: { width: 200, margin: "0 15px" },
      function: (event, newValue) => {
        setSelectedmonth(newValue);
        if (newValue) {
          setToDate(null);
          setFromDate(null);
        }
      },
      className: null,
      type: "name",
    },
    {
      typeId: 2,
      label: "Site",
      options: [{ id: null, name: "ALL" }] || [],
      value: site,
      style: { width: 200, margin: "0 15px", visibility: "hidden" },
      function: (event, newValue) => {
        setSite(newValue);
      },
      className: null,
      type: "name",
    },
  ];

  // Function to handle from date
  const handleFromDate = (value) => {
    if (toDate != null) {
      const toDateCurrent = moment(toDate);
      const fromDateCurrent = moment(value);
      const result = toDateCurrent.diff(fromDateCurrent, "days");
      const alertMessage =
        result < 0 ? "From Date should be before To Date" : "";

      setAlerts({
        visible: result < 0,
        alertMessage,
        alertType: "error",
      });

      if (result >= 0) {
        setFromDate(value);
      }
    } else {
      setFromDate(value);
    }
  };

  // Function to handle To date
  const handleToDate = (value) => {
    const fromDateCurrent = moment(fromDate);
    const toDateCurrent = moment(value);
    const result = toDateCurrent.diff(fromDateCurrent, "days");
    const alertMessage = result < 0 ? "To Date should be after From Date" : "";

    setAlerts({
      visible: result < 0,
      alertMessage,
      alertType: "error",
    });

    if (result >= 0) {
      setToDate(value);
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  // handle submit function for filter the data
  const handleSubmitFilterValue = () => {
    setState({ ...state, right: false });
    setShowFilteredValues(true);
    if (getSiteWiseData) {
      getAssetsList(localStorage.getItem("siteId"), true, true);
    } else getAssetsList(localStorage.getItem("siteId"), false, false);
  };

  const list = (anchor) => (
    <SideBar
      anchor={anchor}
      state={state}
      setState={setState}
      toDate={toDate}
      fromDate={fromDate}
      toggleDrawer={toggleDrawer}
      filteredValues={filteredValues}
      handleSubmitFilterValue={handleSubmitFilterValue}
      handleResetFilter={handleResetFilter}
    />
  );

  // Function to get assets list
  const getAssetsList = async (id, siteWiseData, checked) => {
    const isPath = checkPathExist(endPoints, window.location.pathname);
    if (!isPath) return;

    const filteredData =
      localStorage.getItem("filter") &&
      JSON.parse(localStorage.getItem("filter"));
    const currentLocation = window.location.pathname;
    const findCurrentFilter = filteredData?.find(
      (ele) => ele.pathname === currentLocation
    );
    const filterValues = findCurrentFilter?.value;

    const fromDate =
      filterValues?.find((item) => item.label === "From Date")?.value || null;

    const toDate =
      filterValues?.find((item) => item.label === "To Date")?.value || null;

    const selectedMonth =
      filterValues?.find((item) => item.label === "Month")?.value || null;

    setReportsList([]);
    setReportsListCopied([]);

    setGetSiteWiseData(siteWiseData);

    let payload = {
      accountId: null,
      reportId:
        window.location.pathname == "/fixedAssets"
          ? 6
          : window.location.pathname == "/liabilities"
          ? 8
          : 7,
      fromDate: fromDate,
      monthId: selectedMonth?.monthValue || null,
      toDate: toDate,
      siteId: siteWiseData ? null : id ? id : site && site.id,
      pageNo: 1,
      pageSize: 10,
    };
    try {
      setLoading(true);
      let encryptedPayload = getEncryptPayload(payload, true);
      const response = await AssetsAction.getAssetsList(encryptedPayload);
      if (response && response.data && response.status === 200) {
        setReportsList(response?.data);
        setReportsListCopied(response?.data);
        successAlert("Success");
      } else {
        setReportsList([]);
        setReportsListCopied([]);
        successAlert("No Data available right now!");
      }
    } catch (error) {
      errorAlert("Something went wrong!");
    }

    setLoading(false);
  };

  const navigateToLedger = async (rowData) => {
    const { accountId, accountCode, accountGroupCurrentBalance, name } =
      rowData;

    navigate("/generalLedger", {
      state: {
        id: accountId,
        name: name,
        code: accountCode,
        balance: accountGroupCurrentBalance,
        balanceType: accountGroupCurrentBalance < 0 ? "DEBIT" : "CREDIT",
        backPath:
          window.location.pathname == "/fixedAssets"
            ? "fixedAssets"
            : window.location.pathname == "/liabilities"
            ? "liabilities"
            : "bankBook",
      },
    });
  };

  useEffect(() => {
    setSelectedmonth(null);
    setFromDate(null);
    setToDate(null);
    setShowFilteredValues(false);
    setSelectedSite(null);
  }, [window.location.pathname]);

  useEffect(() => {
    if (!getSiteWiseData) {
      if (restoredSite && Object.keys(restoredSite).length > 0) {
        getAssetsList(restoredSite?.id);
      } else {
        getAssetsList(localStorage.getItem("siteId"));
      }
    } else {
      getAssetsList(localStorage.getItem("siteId"));
    }
  }, [restoredSite, window.location.pathname]);

  useEffect(() => {
    const pathExist = checkPathExist(endPoints, window.location.pathname);
    if (!pathExist) navigate("/error");
  }, [endPoints]);

  const MySwitch = ({ checked, onChange, label }) => (
    <FormControlLabel
      control={<Switch checked={checked} onChange={onChange} />}
      label={label}
    />
  );

  const renderAutocomplete = (label, value, width) => (
    <Grid item>
      {/* <Autocomplete
        options={options || []}
        size="small"
        margin="dense"
        style={{ minWidth: width, maxWidth: 450 }}
        value={value[0]}
        getOptionLabel={(option) => (option ? option.name || option.name : "")}
        getOptionSelected={(option, value) => option.name === value.name}
        // onChange={(event, newValue) => onChange(newValue)}
        multiple={false}
        filterSelectedOptions
        limitTags={limitTags}
        variant="outlined"
        // PopperComponent={CustomPaper}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            onClick={handleClickOpen}
          />
        )}
      /> */}
      <TextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        size="small"
        margin="dense"
        style={{ minWidth: width, maxWidth: 300 }}
        onChange={() => handleClickOpen(label)}
        value={
          value && value.length > 0
            ? value[0]?.name +
              (value.length > 1 ? " +" + (value.length - 1) : "")
            : ""
        }
        onClick={() => handleClickOpen(label)}
      />
    </Grid>
  );

  const handleClickOpen = (event) => {
    setOpen(true);
  };

  const handleKeyDown = (e) => {
    if (
      e.altKey &&
      (e.key === "w" || e.key === "W") &&
      !e.ctrlKey &&
      !e.shiftKey
    ) {
      getAssetsList(
        selectedSite?.id || localStorage.getItem("siteId"),
        !getSiteWiseData,
        !getSiteWiseData
      );

      if (!getSiteWiseData) {
        const filteredSites =
          siteList &&
          siteList.filter((val) => val.alias != "all" && val.alias != "remove");
        setSelectedSites(filteredSites);
      }
    }

    if (
      e.altKey &&
      e.shiftKey &&
      (e.key === "r" || e.key === "R") &&
      !e.ctrlKey
    ) {
      setState({ ...state, right: true });
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  // Debounce function
  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const debouncedGetAccountsList = debounce(async (event, value) => {
    if (searchField) {
      // const valString = event.target.value;

      if (searchField.length > 3) {
        const payload = {
          fromDate,
          monthId: null,
          toDate,
          siteId: parseInt(localStorage.getItem("siteId")),
          accountGroupIds: null,
          tokenData: {
            companyId: "11",
            moduleId: null,
            organizationId: null,
            userCode: null,
            userId: "14000",
            userName: null,
            userRoleId: null,
          },
          financialYearId: 5,
          searchKey: searchField || null,
          reportId: 5,
        };
        const apiUrl = trialBalanceUrl;

        try {
          setLoading(true);
          const response = await userService.postData(apiUrl, payload);

          if (response && response.data && response.data.status === 200) {
            const { accountInfoList } = response.data.data;
            const newList = accountInfoList.map((item) => ({
              ...item,
              accountId: item?.accountId,
              name: item.accountName,

              isAccount: true,
              childList: [], // Make sure to set childList as an empty array
            }));
            setReportsList(newList);
            setReportsListCopied(newList);
            setSearchStatus(true);
            successAlert("Data fetch Successfully");
          } else {
            errorAlert(response?.data?.message);
            setSearchStatus(true);
          }
        } catch (error) {
          console.error("API Call Error:", error);
          setSearchStatus(false);
          setLoading(false);
        } finally {
          setLoading(false);
        }
      }
    }
  });

  const recursivelySearch = (data, searchString) => {
    const matches = [];
    for (let i = 0; i < data?.length; i++) {
      const ele = data[i];
      if (ele.name.toLowerCase().includes(searchString.toLowerCase())) {
        matches.push(ele);
      }
      if (ele.childList) {
        matches.push(...recursivelySearch(ele.childList, searchString));
      }
    }
    return matches;
  };

  const localSearchHandle = () => {
    if (searchField) {
      const matches = recursivelySearch(reportsList, searchField);
      setReportsListCopied(matches);
    } else {
      setReportsListCopied(reportsList);
    }
  };

  const currentPage = window.location.pathname;

  const siteNameHandleDelete = (ele) => {
    userConfig.hideColumn(currentPage, "siteName", ele.id);
    const getHeaderConfig = userConfig.getHeader(currentPage, "siteName");
    setSiteName(getHeaderConfig);
  };
  const siteNameColumnHandle = (val) => {
    if (val !== null) {
      if (val?.alias === "all") {
        userConfig.showAllColumn(currentPage, "siteName");
        setSiteName(userConfig.getHeader(currentPage, "siteName"));
        return;
      }
      if (val?.alias === "remove") {
        userConfig.hideAllColumn(currentPage, "siteName");
        setSiteName(userConfig.getHeader(currentPage, "siteName"));
        return;
      }
      if (val?.id) {
        userConfig.showColumn(currentPage, "siteName", val?.id);
        setSiteName(userConfig.getHeader(currentPage, "siteName"));
      }
    }
  };

  useEffect(() => {
    const currentPage = window.location.pathname;
    const getSiteName = userConfig.getHeader(currentPage, "siteName");
    setSiteName(getSiteName);
  }, [window.location.pathname]);

  const getSiteLength =
    siteName && siteName?.filter((ele) => ele?.isShow).length;
  const emptyArrayObj = [
    {
      name: "-",
      alias: "-",
      isShow: true,
    },
    {
      name: "-",
      alias: "-",
      isShow: true,
    },
    {
      name: "-",
      alias: "-",
      isShow: true,
    },
    {
      name: "-",
      alias: "-",
      isShow: true,
    },
  ];

  const singleArrayObj = [
    {
      name: "Account Group Balance",
      alias: "accountGroupCurrentBalance",
      isShow: true,
    },
  ];

  const getFinalArray =
    getSiteLength && getSiteLength >= 5
      ? singleArrayObj
      : [...singleArrayObj, ...emptyArrayObj];

  const handleResetFilter = () => {
    const filterValuesData =
      localStorage.getItem("filter") &&
      JSON.parse(localStorage.getItem("filter"));
    const currentLocation = window.location.pathname;
    if (filterValuesData) {
      const updatedFilterData = filterValuesData?.filter(
        (ele) => ele?.pathname !== currentLocation
      );
      localStorage.setItem("filter", JSON.stringify(updatedFilterData));
      setFromDate(null);
      setToDate(null);
      setSelectedmonth(null);
      setState({ ...state, right: false });

      if (!getSiteWiseData) {
        if (restoredSite && Object.keys(restoredSite).length > 0) {
          getAssetsList(restoredSite?.id);
        } else {
          getAssetsList(localStorage.getItem("siteId"));
        }
      } else {
        getAssetsList(localStorage.getItem("siteId"));
      }
    }
  };

  useEffect(() => {
    if (window.location.pathname == "/fixedAssets") {
      const filteredData =
        localStorage.getItem("filter") &&
        JSON.parse(localStorage.getItem("filter"));
      const currentLocation = window.location.pathname;
      if (filteredData) {
        const findCurrentFilter = filteredData.find(
          (ele) => ele.pathname === currentLocation
        );

        if (findCurrentFilter) {
          const filterValues = findCurrentFilter.value;
          setFromDate(
            filterValues.find((item) => item.label === "From Date")?.value ||
              null
          );
          setToDate(
            filterValues.find((item) => item.label === "To Date")?.value || null
          );
          setSelectedmonth(
            filterValues.find((item) => item.label === "Month")?.value || null
          );
        }
      } else {
        setFromDate(null);
        setToDate(null);
        setSelectedmonth(null);
        setState({ ...state, right: false });
      }
    }

    if (window.location.pathname == "/liabilities") {
      const filteredData =
        localStorage.getItem("filter") &&
        JSON.parse(localStorage.getItem("filter"));
      const currentLocation = window.location.pathname;
      if (filteredData) {
        const findCurrentFilter = filteredData.find(
          (ele) => ele.pathname === currentLocation
        );

        if (findCurrentFilter) {
          const filterValues = findCurrentFilter.value;
          setFromDate(
            filterValues.find((item) => item.label === "From Date")?.value ||
              null
          );
          setToDate(
            filterValues.find((item) => item.label === "To Date")?.value || null
          );
          setSelectedmonth(
            filterValues.find((item) => item.label === "Month")?.value || null
          );
        }
      } else {
        setFromDate(null);
        setToDate(null);
        setSelectedmonth(null);
        setState({ ...state, right: false });
      }
    }
  }, [window.location.pathname, restoredSite]);

  const isFilterApplied = fromDate || toDate || selectedmonth;

  return (
    <>
      <Paper
        className={classes.paper}
        elevation={0}
        style={{ width: "100%", overflow: "auto" }}
      >
        <Container style={{ maxWidth: "100%" }}>
          <Grid
            container
            style={{ margin: "0 0 10px 0", alignItems: "center" }}
          >
            <Header
              gridSize="3"
              source={report}
              headerText={
                window.location.pathname == "/fixedAssets"
                  ? "Fixed Assets"
                  : window.location.pathname == "/bankBook"
                  ? "Bank Book"
                  : "Liabilities"
              }
            />
            <Grid
              item
              xs={9}
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
            >
              <Grid item>
                <MySwitch
                  checked={isBalanceWiseData}
                  onChange={(e) => {
                    getAssetsList(
                      localStorage.getItem("siteId"),
                      getSiteWiseData
                    );
                    setIsBalanceWiseData(e.target.checked);
                  }}
                  label={!isBalanceWiseData ? "Balance On" : "Balance Off"}
                />
              </Grid>
              <Grid item>
                <MySwitch
                  checked={getSiteWiseData}
                  onChange={(e) => {
                    getAssetsList(
                      selectedSite?.id || localStorage.getItem("siteId"),
                      e.target.checked,
                      e.target.checked
                    );

                    if (e.target.checked) {
                      const filteredSites =
                        siteList &&
                        siteList.filter(
                          (val) => val.alias != "all" && val.alias != "remove"
                        );
                      setSelectedSites(filteredSites);
                    }
                  }}
                  label={!getSiteWiseData ? "Site Wise On" : "Site Wise Off"}
                />
              </Grid>

              <Grid item>
                <TextField
                  value={searchField}
                  onChange={(e) => setSearchField(e.target.value)}
                  margin="dense"
                  size="small"
                  style={{
                    maxWidth: 250,
                  }}
                  label="Search"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {!getSiteWiseData && (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={localSearchHandle}
                          >
                            <FcSearch />
                          </IconButton>
                        )}
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={debouncedGetAccountsList}
                        >
                          <TbWorldSearch />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/* <Grid item>
                <Autocomplete
                  options={siteList && [...siteList, { id: null, name: "All" }] || []}
                  size="small"
                  margin="dense"
                  style={{ width: 200 }}
                  value={selectedSite || ""}
                  getOptionLabel={(option) =>
                    option ? option.name || option.name : ""
                  }
                  getOptionSelected={(option, value) => option.name === value.name}
                  onChange={(event, newValue) => {

                    setSelectedSite(newValue)
                  }}
                  variant="outlined"

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Site"
                      variant="outlined"
                    />
                  )}
                />
              </Grid> */}

              {getSiteWiseData &&
                renderAutocomplete(
                  "Site",
                  siteName?.filter((ele) => ele.isShow),
                  "250px"
                )}

              {
                <AutoDialogBox
                  open={open}
                  columnHandle={siteNameColumnHandle}
                  handleDelete={siteNameHandleDelete}
                  setOpen={setOpen}
                  setScroll={setScroll}
                  // scroll={scroll}
                  scroll={"paper"}
                  columns={siteName || []}
                  heading={"Site"}
                  autoCompName={"Site"}
                />
              }
              <Grid item>
                {["right"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    {isFilterApplied ? (
                      <Badge color="primary" variant="dot">
                        <IconButton
                          style={{ background: "#c35151", color: "#fff" }}
                          onClick={toggleDrawer(anchor, true)}
                        >
                          <TuneIcon />
                        </IconButton>
                      </Badge>
                    ) : (
                      <IconButton
                        style={{ background: "#c35151", color: "#fff" }}
                        onClick={toggleDrawer(anchor, true)}
                      >
                        <TuneIcon />
                      </IconButton>
                    )}
                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                    >
                      {list(anchor)}
                    </Drawer>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
          </Grid>

          {/* {showFilteredValues && (
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              {fromDate &&
                renderChip(
                  "From",
                  fromDate && moment(fromDate).format("MMMM Do YYYY")
                )}
              {toDate &&
                renderChip(
                  "To",
                  toDate && moment(toDate).format("MMMM Do YYYY")
                )}
              {selectedmonth &&
                renderChip("Month", selectedmonth && selectedmonth?.name)}
            </Stack>
          )} */}

          {!getSiteWiseData ? (
            <ReportsTable
              setOpenDefault={setOpenDefault}
              data={reportsListCopied}
              mainData={reportsList}
              setData={setReportsList}
              pageNo={pageNo}
              setPageNo={setPageNo}
              totalPages={totalPages}
              setTotalPages={setTotalPages}
              pageSize={pageSize}
              setPageSize={setPageSize}
              successAlert={successAlert}
              errorAlert={errorAlert}
              site={selectedSite}
              fromDate={fromDate}
              toDate={toDate}
              month={selectedmonth}
              navigateToLedger={navigateToLedger}
              setLoading={setLoading}
              searchField={searchStatus}
              setSearchField={searchStatus}
              isBalanceWiseData={isBalanceWiseData}
            />
          ) : (
            <SiteWiseTable
              openDefault={openDefault}
              data={reportsListCopied}
              mainData={reportsList}
              setData={setReportsList}
              setLoading={setLoading}
              errorAlert={errorAlert}
              successAlert={successAlert}
              siteList={siteName}
              // selectedColumns={[
              //   {
              //     name: "Account Group Balance",
              //     alias: "accountGroupCurrentBalance",
              //     isShow: true,
              //   },
              // ]}
              columnConfig={getFinalArray || []}
              pathname={window.location.pathname}
              fromDate={fromDate}
              toDate={toDate}
              monthId={selectedmonth?.monthValue}
              setReportsListCopied={setReportsListCopied}
              isBalanceWiseData={isBalanceWiseData}
            />
          )}
        </Container>
      </Paper>

      {/* ==============Alerts=============== */}
      <AlertBar
        visible={alerts?.visible}
        closeAlertBar={closeAlertBar}
        alertMessage={alerts?.alertMessage}
        alertType={alerts?.alertType}
      />

      {loading && <LoadingComponent />}
    </>
  );
};

export default withStyles(styles)(Index);
