import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Pagination,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Paper,
  IconButton,
  Autocomplete,
  TextField,
  Collapse,
  Divider,
  TableCell,
  InputAdornment,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FcInfo } from "react-icons/fc";
import { headers } from "./util";
import moment from "moment";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import InfoIcon from "@mui/icons-material/Info";
import { DataStatus, updateNestedItem, zeroBalanceReportFilter } from "../utils/index";
import { pageSizes } from "../Account/util";
import { StyledTableCell, StyledTableRow } from "../utils/index";
// import Color from "color";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { FaDollarSign, FaFontAwesome, FaRupeeSign } from "react-icons/fa";
import { NumberFormatter } from "../CommonComps/NumberFormatter";
import AmountInfo from "../CommonComps/AmountInfo";
import { getEncryptPayload } from "../Network_Utility";
import { reportChildListUrl, trialBalanceUrl } from "../config";
import { TbWorldSearch } from "react-icons/tb";
import { userService } from "../userService";
import { AutoSizer, InfiniteLoader, List } from "react-virtualized";
import ColorInfo from "../CommonComps/ColorInfo";
import MySwitch from "../CommonComps/Switch";
import { Editedtext } from "../CommonComps/TextTemplate";

// function getColorForLevel(level) {
//   // Define a color scheme based on levels
//   const colorSchemes = [
//     "#DDEEFF", // Dark Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//   ];

//   // Use modulo to cycle through colors
//   return colorSchemes[level % colorSchemes.length];
// }

function Row({
  childRow,
  index,
  handleRevert,
  site,
  level,
  item,
  color,
  setLoading,
  successAlert,
  errorAlert,
  getApprovedBal,
  tableData,
  setData,
  siteId,
  fromDate,
  toDate,
  monthId,
  showOpeningBalance,
  navigateToLedger,
  isBalanceWiseData
}) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { childList } = childRow;
  const [isAccount, setIsAccount] = useState(false);
  const [rowData, setRowData] = useState(childRow || {});
  const [accountSerchValue, setAccountSearchValue] = React.useState("");
  const [pageNum, setPageNum] = React.useState(0);
  const [balanceStatus, setBalanceStatus] = useState(true);
  const [infiniteLoaderKey, setInfiniteLoaderKey] = useState(0); 

  const handleClickIcon = (row) => {
    if (!isCollapsed) {
      if (!row.childList || (row.childList && row.childList.length == 0)) {
        makeAPICallOnLeafNode(row);
        // const permissionExist = checkPermissionExist(endPoints, trialBalanceUrl)
        // if (permissionExist)

        // else errorAlert(`Unautharized to access accounts trial balance of ${row.name} group.`)
      } else setIsCollapsed(!isCollapsed);
      // Set isCollapsed to true before making the API call
    } else setIsCollapsed(!isCollapsed); // Toggle isCollapsed state
  };

  const makeAPICallOnLeafNode = async (row) => {
    const payload = {
      reportId: 5,
      groupId: row?.id,
      siteId: null,
      monthId: monthId,
      fromDate: fromDate,
      toDate: toDate,
    };

    const encryptedPayload = getEncryptPayload(payload, true);

    const apiUrl = reportChildListUrl;

    try {
      setLoading(true);
      const response = await userService.postData(
        apiUrl,
        encryptedPayload,
        null,
        true
      );

      if (response && response.data && response.data.status == 200) {
        setLoading(false);
        if (
          response?.data?.data &&
          response?.data?.data.length > 0 &&
          response?.data?.data[0]?.childList?.length > 0
        ) {
          const updatedRow = {
            ...row,
            childList: response?.data?.data[0]?.childList,
          };
          const balanceFilterRow = {
            ...row,
            childList: zeroBalanceReportFilter(
              response?.data?.data[0]?.childList
            ),
          };
          if (isBalanceWiseData) {
            setRowData(balanceFilterRow);
            if (balanceFilterRow?.childList?.length > 0) {
              setIsCollapsed(true);
            } else {
              setIsCollapsed(false);
              errorAlert("There are no accounts that have a balance!");
            }
          } else {
            setRowData(updatedRow);
            if (updatedRow?.childList?.length > 0) {
              setIsCollapsed(true);
            } else {
              setIsCollapsed(false);
            }
          }

        } else getAccountsList(row, 1, false, balanceStatus);
      } else {
        setLoading(false);
        setIsCollapsed(false);
        // Handle API error
        errorAlert(response?.data?.message);
        console.error("API Error:", response.data.message);
      }
    } catch (error) {
      setLoading(false);
      setIsCollapsed(false);
      // Handle API call error
      console.error("API Call Error:", error);
    } finally {
      // setLoading(false);
    }
  };

  const getAccountsList = async (row, pageN, isSearch, balanceStatus, fetch) => {
    const payload = {
      fromDate: fromDate,
      monthId: monthId,
      toDate: toDate,
      siteId: null,
      accountGroupIds: [row?.id],
      tokenData: {
        companyId: "11",
        moduleId: null,
        organizationId: null,
        userCode: null,
        userId: "14000",
        userName: null,
        userRoleId: null,
      },
      financialYearId: 5,
      pageNo: pageN || 1,
      pageSize: 20,
      searchKey: accountSerchValue
        ? accountSerchValue?.trim().replace(/\s+/g, " ")
        : "",
      isHavingTransaction: balanceStatus,
    };

    const apiUrl = trialBalanceUrl;

    try {
      pageN === 1 && setLoading(true);
      const response = await userService.postData(apiUrl, payload);

      if (response && response.data && response.data.status === 200) {
        pageN === 1 && setLoading(false);
        const { accountInfoList } = response.data.data?.data;

        if (accountInfoList && accountInfoList.length > 0) {
          setIsCollapsed(true);
          setIsAccount(true);
          const newList = accountInfoList.map((item) => ({
            ...item,
            accountId: item?.accountId,
            name: item.accountName,

            isAccount: true,
            childList: [], // Make sure to set childList as an empty array
          }));

          const updatedRow =
            rowData?.childList && !isSearch && !fetch
              ? { ...row, childList: [...rowData?.childList, ...newList] }
              : { ...row, childList: newList };
          // const updatedRow = { ...row, childList: newList };
          setRowData(updatedRow);

          // Update the main data array with the updated row
          // const updatedData = updateNestedItem(tableData, updatedRow)
          // setData(updatedData);

          // Toggle isCollapsed state
        } else {
          if (pageN === 1) {
            const updatedRow = { ...row, childList: [] };
            setRowData(updatedRow);
            setLoading(false);
            successAlert(`No Account available for ${row.name}`);
          }
          // If there are no child items, just toggle isCollapsed state
          // setIsCollapsed(!isCollapsed);
          if (rowData.childList) {
            if (rowData.childList.length === 0) {
              successAlert(`No Account available for ${row.name}`);
            }
          } else {
            successAlert(`No Account available for ${row.name}`);
          }
        }
      } else {
        pageN === 1 && setLoading(false);
        // Handle API error
        errorAlert(response?.data?.message);
        console.error("API Error:", response.data.message);
      }
    } catch (error) {
      pageN === 1 && setLoading(false);
      if (rowData.childList) {
        if (rowData.childList.length === 0) {
          successAlert(`No Account available for ${row.name}`);
        }
      } else {
        successAlert(`No Account available for ${row.name}`);
      }
      // Handle API call error
      console.error("API Call Error:", error);
    } finally {
      // setLoading(false);
    }
  };

  // const color = getColorForLevel(level);
  const paddingLeft = `10px!important`;

  useEffect(() => {
    childRow && setRowData(childRow);
  }, [childRow]);

  const resetInfiniteLoader = () => {
    setInfiniteLoaderKey((prevKey) => prevKey + 1); // Increment the key to force re-render
    setPageNum(1)
  };

  const isAccountLoaded = ({ index }) => {
    if (!isAccount) return;
    const loadedItem = !!rowData.childList[index];
    return loadedItem;
  };

  const loadMoreRows = ({ startIndex, stopIndex }) => {
    let quotient = Math.ceil(stopIndex / 20);
    const pageSize = quotient + 1;
    console.log(pageSize);
    if (pageNum === pageSize) {
      return;
    }
    setPageNum(pageSize);
    getAccountsList(childRow, pageSize, false, balanceStatus);
  };

  const searchAccountHandle = () => {
    setTimeout(() => {                          
      resetInfiniteLoader();
    }, 500);
    getAccountsList(childRow, 1, true, balanceStatus);
  };

  return (
    <React.Fragment>
      <StyledTableRow
        className="plRow"
        sx={{
          cursor: "pointer",
          backgroundColor: `${color}!important`,
          marginTop: "1rem!important",
        }}
      >
        {rowData && !rowData?.isAccount ? (
          <StyledTableCell
            style={{
              padding: "5px 0px!important",
              paddingLeft: paddingLeft,
            }}
          >
            <Typography variant="body2" className="table_value">
              <IconButton
                aria-label="expand rowData"
                size="small"
                onClick={() => handleClickIcon(rowData)}
              >
                {isCollapsed ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
              <span style={{ color: rowData?.isAccount && "blue" }}>
              <Editedtext num="50" text={rowData?.name} />
                {/* {rowData?.name} */}
              </span>
            </Typography>
          </StyledTableCell>
        ) : (
          <StyledTableCell
            style={{
              padding: "5px 0px!important",
              paddingLeft: paddingLeft,
            }}
          >
            <Typography variant="body2" className="table_value">
              <IconButton disabled>
                <KeyboardArrowUpIcon style={{ visibility: "hidden" }} />
              </IconButton>
              <span style={{ color: rowData?.isAccount && "blue" }}>
              <Editedtext num="50" text={rowData?.name} />
                {/* {rowData?.name} */}
              </span>
            </Typography>
          </StyledTableCell>
        )}

        <StyledTableCell sx={{ padding: "5px 0px!important" }}>
          {getApprovedBal ? (
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <span
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color:
                    rowData?.siteDataList?.length > 0 &&
                    rowData?.siteDataList?.find((val) => val?.siteId === siteId)
                      ?.approvedAccountGroupCurrentBalance
                      ? rowData?.siteDataList?.find(
                          (val) => val?.siteId === siteId
                        )?.approvedAccountGroupCurrentBalance -
                          (rowData?.siteDataList?.find(
                            (val) => val?.siteId === siteId
                          )?.approvedOpeningBalance || 0) <
                        0
                        ? "green"
                        : "red"
                      : rowData?.siteDataList?.find(
                          (val) => val?.siteId === siteId
                        )?.approvedClosingBalance
                      ? rowData?.siteDataList?.find(
                          (val) => val?.siteId === siteId
                        )?.approvedClosingBalance -
                          (rowData?.siteDataList?.find(
                            (val) => val?.siteId === siteId
                          )?.approvedOpeningBalance || 0) <
                        0
                        ? "green"
                        : "red"
                      : "red",
                }}
              >
                {rowData?.siteDataList?.length > 0 &&
                rowData?.siteDataList?.find((val) => val?.siteId === siteId)
                  ?.approvedAccountGroupCurrentBalance ? (
                  rowData?.siteDataList?.find((val) => val?.siteId === siteId)
                    ?.approvedOpeningBalance ||
                  rowData?.siteDataList?.find((val) => val?.siteId === siteId)
                    ?.approvedAccountGroupCurrentBalance ? (
                    <>
                      {showOpeningBalance && (
                        <span style={{ color: "#2d269f", padding: "0px 2px" }}>
                          <AmountInfo
                            amount={
                              rowData?.siteDataList?.find(
                                (val) => val?.siteId === siteId
                              )?.openingBalance || 0
                            }
                          />
                          <span
                            style={{
                              color: "#000",
                              display: "inline",
                              marginRight: "3px",
                              marginLeft: "5px",
                            }}
                          >
                            |
                          </span>
                        </span>
                      )}
                      <AmountInfo
                        amount={
                          (rowData?.siteDataList?.find(
                            (val) => val?.siteId === siteId
                          )?.approvedAccountGroupCurrentBalance || 0) -
                          (rowData?.siteDataList?.find(
                            (val) => val?.siteId === siteId
                          )?.approvedOpeningBalance || 0)
                        }
                      />
                    </>
                  ) : (
                    "-"
                  )
                ) : rowData?.siteDataList?.find((val) => val?.siteId === siteId)
                    ?.approvedClosingBalance ? (
                  rowData?.siteDataList?.find((val) => val?.siteId === siteId)
                    ?.approvedOpeningBalance ||
                  rowData?.siteDataList?.find((val) => val?.siteId === siteId)
                    ?.approvedClosingBalance ? (
                    <>
                      {showOpeningBalance && (
                        <span style={{ color: "#2d269f", padding: "0px 2px" }}>
                          <AmountInfo
                            amount={
                              rowData?.siteDataList?.find(
                                (val) => val?.siteId === siteId
                              )?.openingBalance || 0
                            }
                          />
                          <span
                            style={{
                              color: "#000",
                              display: "inline",
                              marginRight: "3px",
                              marginLeft: "5px",
                            }}
                          >
                            |
                          </span>
                        </span>
                      )}
                      <AmountInfo
                        amount={
                          (rowData?.siteDataList?.find(
                            (val) => val?.siteId === siteId
                          )?.approvedClosingBalance || 0) -
                          (rowData?.siteDataList?.find(
                            (val) => val?.siteId === siteId
                          )?.approvedOpeningBalance || 0)
                        }
                      />
                    </>
                  ) : (
                    "-"
                  )
                ) : (
                  "-"
                )}
              </span>
            </span>
          ) : (
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <span
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color:
                    rowData?.siteDataList?.find((val) => val?.siteId === siteId)
                      ?.accountGroupCurrentBalance -
                      (rowData?.siteDataList?.find(
                        (val) => val?.siteId === siteId
                      )?.openingBalance || 0) <
                    0
                      ? "green"
                      : "red",
                }}
              >
                {rowData?.siteDataList?.length > 0 &&
                rowData?.siteDataList?.find((val) => val?.siteId === siteId)
                  ?.openingBalance != undefined &&
                rowData?.siteDataList?.find((val) => val?.siteId === siteId)
                  ?.accountGroupCurrentBalance != undefined ? (
                  <>
                    {showOpeningBalance && (
                      <span style={{ color: "#2d269f", padding: "0px 2px" }}>
                        <AmountInfo
                          amount={
                            rowData?.siteDataList?.find(
                              (val) => val?.siteId === siteId
                            )?.openingBalance || 0
                          }
                        />
                        <span
                          style={{
                            color: "#000",
                            display: "inline",
                            marginRight: "3px",
                            marginLeft: "5px",
                          }}
                        >
                          |
                        </span>
                      </span>
                    )}
                    <AmountInfo
                      amount={
                        (rowData?.siteDataList?.find(
                          (val) => val?.siteId === siteId
                        )?.accountGroupCurrentBalance || 0) -
                        (rowData?.siteDataList?.find(
                          (val) => val?.siteId === siteId
                        )?.openingBalance || 0)
                      }
                    />
                  </>
                ) : (
                  "-"
                )}
              </span>
            </span>
          )}
        </StyledTableCell>
      </StyledTableRow>

      <StyledTableRow>
        <StyledTableCell
          colSpan={3}
          sx={{ padding: "5px 0px!important", borderBottom: "none" }}
        >
          <Collapse in={isCollapsed}>
            <div>
              {isAccount && rowData && (
                <Grid container alignItems={"center"} spacing={3}>
                  <Grid item sx={{ paddingLeft: "9px" }}>
                    <TextField
                      value={accountSerchValue}
                      onChange={(e) => setAccountSearchValue(e.target.value)}
                      margin="dense"
                      size="small"
                      style={{
                        maxWidth: 250,
                      }}
                      label="Search"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={searchAccountHandle}
                            >
                              <TbWorldSearch />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <MySwitch
                      checked={balanceStatus}
                      onChange={(e) => {
                        setBalanceStatus(e.target.checked);
                        setTimeout(() => {                          
                          resetInfiniteLoader()
                        }, 500);
                        getAccountsList(childRow, 1, Boolean(accountSerchValue?.trim()), e.target.checked, true);
                      }}
                      label={!balanceStatus ? "Balance On" : "Balance Off"}
                    />
                  </Grid>
                </Grid>
              )}
              <TableContainer>
                <Table>
                  <TableBody>
                    {isAccount && rowData ? (
                      <AutoSizer style={{ height: "300px" }}>
                        {({ height, width }) => {
                          const itemsPerRow = 1;

                          return (
                            rowData.childList &&
                            rowData.childList.length > 0 && (
                              <InfiniteLoader
                              key={infiniteLoaderKey}
                                isRowLoaded={isAccountLoaded}
                                loadMoreRows={loadMoreRows}
                                rowCount={
                                  Math.ceil(
                                    rowData.childList?.length / itemsPerRow
                                  ) + 1
                                }
                              >
                                {({ onRowsRendered, registerChild }) => (
                                  <List
                                    width={width}
                                    height={300}
                                    rowHeight={50}
                                    rowCount={Math.ceil(
                                      rowData.childList?.length / itemsPerRow
                                    )}
                                    overscanRowCount={3}
                                    rowRenderer={({ index, key, style }) => {
                                      const startIndex = index * itemsPerRow;
                                      const endIndex = Math.min(
                                        startIndex + itemsPerRow,
                                        rowData.childList?.length
                                      );
                                      const items = rowData.childList
                                        ?.slice(startIndex, endIndex)
                                        .map((val, ind) => (
                                          <>
                                            <StyledTableCell
                                              style={{
                                                paddingLeft: paddingLeft,
                                              }}
                                            >
                                              <Typography
                                                variant="body2"
                                                className="table_value"
                                              >
                                                <IconButton disabled>
                                                  <KeyboardArrowUpIcon
                                                    style={{
                                                      visibility: "hidden",
                                                    }}
                                                  />
                                                </IconButton>
                                                <span
                                                  style={{
                                                    color:
                                                      val?.isAccount && "blue",
                                                  }}
                                                  onClick={() =>
                                                    navigateToLedger(val)
                                                  }
                                                >
                                                  <Editedtext num="50" text={val?.name} />
                                                  {/* {val?.name} */}
                                                </span>
                                              </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell
                                              sx={{
                                                padding: "5px 0px!important",
                                              }}
                                            >
                                              {getApprovedBal ? (
                                                <span
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    alignItems: "center",
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      cursor: "pointer",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent:
                                                        "space-between",
                                                      color:
                                                        rowData?.siteDataList
                                                          ?.length > 0 &&
                                                        rowData?.siteDataList?.find(
                                                          (val) =>
                                                            val?.siteId ===
                                                            siteId
                                                        )
                                                          ?.approvedAccountGroupCurrentBalance
                                                          ? rowData?.siteDataList?.find(
                                                              (val) =>
                                                                val?.siteId ===
                                                                siteId
                                                            )
                                                              ?.approvedAccountGroupCurrentBalance -
                                                              (rowData?.siteDataList?.find(
                                                                (val) =>
                                                                  val?.siteId ===
                                                                  siteId
                                                              )
                                                                ?.approvedOpeningBalance ||
                                                                0) <
                                                            0
                                                            ? "green"
                                                            : "red"
                                                          : rowData?.siteDataList?.find(
                                                              (val) =>
                                                                val?.siteId ===
                                                                siteId
                                                            )
                                                              ?.approvedClosingBalance
                                                          ? rowData?.siteDataList?.find(
                                                              (val) =>
                                                                val?.siteId ===
                                                                siteId
                                                            )
                                                              ?.approvedClosingBalance -
                                                              (rowData?.siteDataList?.find(
                                                                (val) =>
                                                                  val?.siteId ===
                                                                  siteId
                                                              )
                                                                ?.approvedOpeningBalance ||
                                                                0) <
                                                            0
                                                            ? "green"
                                                            : "red"
                                                          : "red",
                                                    }}
                                                  >
                                                    {rowData?.siteDataList
                                                      ?.length > 0 &&
                                                    rowData?.siteDataList?.find(
                                                      (val) =>
                                                        val?.siteId === siteId
                                                    )
                                                      ?.approvedAccountGroupCurrentBalance ? (
                                                      rowData?.siteDataList?.find(
                                                        (val) =>
                                                          val?.siteId === siteId
                                                      )
                                                        ?.approvedOpeningBalance ||
                                                      rowData?.siteDataList?.find(
                                                        (val) =>
                                                          val?.siteId === siteId
                                                      )
                                                        ?.approvedAccountGroupCurrentBalance ? (
                                                        <>
                                                          {showOpeningBalance && (
                                                            <span
                                                              style={{
                                                                color:
                                                                  "#2d269f",
                                                                padding:
                                                                  "0px 2px",
                                                              }}
                                                            >
                                                              <AmountInfo
                                                                amount={
                                                                  rowData?.siteDataList?.find(
                                                                    (val) =>
                                                                      val?.siteId ===
                                                                      siteId
                                                                  )
                                                                    ?.openingBalance ||
                                                                  0
                                                                }
                                                              />
                                                              <span
                                                                style={{
                                                                  color: "#000",
                                                                  display:
                                                                    "inline",
                                                                  marginRight:
                                                                    "3px",
                                                                  marginLeft:
                                                                    "5px",
                                                                }}
                                                              >
                                                                |
                                                              </span>
                                                            </span>
                                                          )}
                                                          <AmountInfo
                                                            amount={
                                                              (rowData?.siteDataList?.find(
                                                                (val) =>
                                                                  val?.siteId ===
                                                                  siteId
                                                              )
                                                                ?.approvedAccountGroupCurrentBalance ||
                                                                0) -
                                                              (rowData?.siteDataList?.find(
                                                                (val) =>
                                                                  val?.siteId ===
                                                                  siteId
                                                              )
                                                                ?.approvedOpeningBalance ||
                                                                0)
                                                            }
                                                          />
                                                        </>
                                                      ) : (
                                                        "-"
                                                      )
                                                    ) : rowData?.siteDataList?.find(
                                                        (val) =>
                                                          val?.siteId === siteId
                                                      )
                                                        ?.approvedClosingBalance ? (
                                                      rowData?.siteDataList?.find(
                                                        (val) =>
                                                          val?.siteId === siteId
                                                      )
                                                        ?.approvedOpeningBalance ||
                                                      rowData?.siteDataList?.find(
                                                        (val) =>
                                                          val?.siteId === siteId
                                                      )
                                                        ?.approvedClosingBalance ? (
                                                        <>
                                                          {showOpeningBalance && (
                                                            <span
                                                              style={{
                                                                color:
                                                                  "#2d269f",
                                                                padding:
                                                                  "0px 2px",
                                                              }}
                                                            >
                                                              <AmountInfo
                                                                amount={
                                                                  rowData?.siteDataList?.find(
                                                                    (val) =>
                                                                      val?.siteId ===
                                                                      siteId
                                                                  )
                                                                    ?.openingBalance ||
                                                                  0
                                                                }
                                                              />
                                                              <span
                                                                style={{
                                                                  color: "#000",
                                                                  display:
                                                                    "inline",
                                                                  marginRight:
                                                                    "3px",
                                                                  marginLeft:
                                                                    "5px",
                                                                }}
                                                              >
                                                                |
                                                              </span>
                                                            </span>
                                                          )}
                                                          <AmountInfo
                                                            amount={
                                                              (rowData?.siteDataList?.find(
                                                                (val) =>
                                                                  val?.siteId ===
                                                                  siteId
                                                              )
                                                                ?.approvedClosingBalance ||
                                                                0) -
                                                              (rowData?.siteDataList?.find(
                                                                (val) =>
                                                                  val?.siteId ===
                                                                  siteId
                                                              )
                                                                ?.approvedOpeningBalance ||
                                                                0)
                                                            }
                                                          />
                                                        </>
                                                      ) : (
                                                        "-"
                                                      )
                                                    ) : (
                                                      "-"
                                                    )}
                                                  </span>
                                                </span>
                                              ) : (
                                                <span
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    alignItems: "center",
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      cursor: "pointer",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent:
                                                        "space-between",
                                                      color:
                                                        rowData?.siteDataList?.find(
                                                          (val) =>
                                                            val?.siteId ===
                                                            siteId
                                                        )
                                                          ?.accountGroupCurrentBalance -
                                                          (rowData?.siteDataList?.find(
                                                            (val) =>
                                                              val?.siteId ===
                                                              siteId
                                                          )?.openingBalance ||
                                                            0) <
                                                        0
                                                          ? "green"
                                                          : "red",
                                                    }}
                                                  >
                                                    {rowData?.siteDataList
                                                      ?.length > 0 &&
                                                    rowData?.siteDataList?.find(
                                                      (val) =>
                                                        val?.siteId === siteId
                                                    )?.openingBalance !=
                                                      undefined &&
                                                    rowData?.siteDataList?.find(
                                                      (val) =>
                                                        val?.siteId === siteId
                                                    )
                                                      ?.accountGroupCurrentBalance !=
                                                      undefined ? (
                                                      <>
                                                        {showOpeningBalance && (
                                                          <span
                                                            style={{
                                                              color: "#2d269f",
                                                              padding:
                                                                "0px 2px",
                                                            }}
                                                          >
                                                            <AmountInfo
                                                              amount={
                                                                rowData?.siteDataList?.find(
                                                                  (val) =>
                                                                    val?.siteId ===
                                                                    siteId
                                                                )
                                                                  ?.openingBalance ||
                                                                0
                                                              }
                                                            />
                                                            <span
                                                              style={{
                                                                color: "#000",
                                                                display:
                                                                  "inline",
                                                                marginRight:
                                                                  "3px",
                                                                marginLeft:
                                                                  "5px",
                                                              }}
                                                            >
                                                              |
                                                            </span>
                                                          </span>
                                                        )}
                                                        <AmountInfo
                                                          amount={
                                                            (rowData?.siteDataList?.find(
                                                              (val) =>
                                                                val?.siteId ===
                                                                siteId
                                                            )
                                                              ?.accountGroupCurrentBalance ||
                                                              0) -
                                                            (rowData?.siteDataList?.find(
                                                              (val) =>
                                                                val?.siteId ===
                                                                siteId
                                                            )?.openingBalance ||
                                                              0)
                                                          }
                                                        />
                                                      </>
                                                    ) : (
                                                      "-"
                                                    )}
                                                  </span>
                                                </span>
                                              )}
                                            </StyledTableCell>
                                          </>
                                        ));

                                      return (
                                        <StyledTableRow key={key} style={style}>
                                          {items}
                                        </StyledTableRow>
                                      );
                                    }}
                                  />
                                )}
                              </InfiniteLoader>
                            )
                          );
                        }}
                      </AutoSizer>
                    ) : (
                      rowData &&
                      rowData.childList &&
                      rowData.childList.map((childRow, childIndex) => (
                        <Row
                          key={childRow.name}
                          childRow={childRow}
                          index={childIndex + 1}
                          handleRevert={handleRevert}
                          site={site}
                          level={level + 1}
                          item={item}
                          color={"#F5FAFF"}
                          setLoading={setLoading}
                          successAlert={successAlert}
                          errorAlert={errorAlert}
                          getApprovedBal={getApprovedBal}
                          tableData={tableData}
                          setData={setData}
                          siteId={siteId}
                          fromDate={fromDate}
                          toDate={toDate}
                          monthId={monthId}
                          showOpeningBalance={showOpeningBalance}
                          isBalanceWiseData={isBalanceWiseData}
                        />
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function BalanceSheetSiteWiseTable({
  data,
  setData,
  pageNo,
  setPageNo,
  totalPages,
  setPageSize,
  pageSize,
  handleRevert,
  site,

  setLoading,
  successAlert,
  errorAlert,
  getApprovedBal,
  siteId,
  siteList,
  netAmountArray,
  fromDate,
  toDate,
  monthId,
  showOpeningBalance,
  navigateToLedger,
  isBalanceWiseData
}) {
  const onPageChange = (event, value) => setPageNo(value);

  // Filter out the "Income" and "Expenses" sections
  const filteredData = Object.keys(data)
    .filter(
      (key) =>
        key === "leftAccountGroupFetchResponseList" ||
        key === "rightAccountGroupFetchResponseList"
    )
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});

  const reversedFilteredData = Object.keys(filteredData)
    .reverse()
    .reduce((obj, key) => {
      obj[key] = filteredData[key];
      return obj;
    }, {});

  const siteWiseBal =
    getApprovedBal && data?.siteApprovedNetBalanceMap
      ? data?.siteApprovedNetBalanceMap
      : data?.siteNetBalanceMap;

  // Utility function for calculating color
  const calculateColor = (
    dataList,
    siteId,
    currentBalanceKey,
    openingBalanceKey
  ) => {
    const totalBalance =
      dataList &&
      dataList.reduce((total, data) => {
        const currentBalance =
          data.siteDataList?.find((val) => val?.siteId === siteId)?.[
            currentBalanceKey
          ] || 0;
        const openingBalance =
          data.siteDataList?.find((val) => val?.siteId === siteId)?.[
            openingBalanceKey
          ] || 0;
        return total + (currentBalance - openingBalance);
      }, 0);

    // Additional check for exact zero balance
    if (totalBalance > 0) {
      return "red";
    } else if (totalBalance < 0) {
      return "green";
    } else {
      return "black"; // Adjust color for zero balance as needed
    }
  };

  // Utility function for calculating amount
  const calculateAmount = (
    dataList,
    siteId,
    currentBalanceKey,
    openingBalanceKey,
    type
  ) => {
    return (
      dataList &&
      dataList.reduce((total, data) => {
        const currentBalance =
          data.siteDataList?.find((val) => val?.siteId === siteId)?.[
            currentBalanceKey
          ] || 0;
        const openingBalance =
          data.siteDataList?.find((val) => val?.siteId === siteId)?.[
            openingBalanceKey
          ] || 0;
        return total + (currentBalance - openingBalance);
      }, 0)
    );
  };

  const renderNetAmountRow = (siteId, item, balance, type) => {
    const isLoss = balance < 0 && item === "leftAccountGroupFetchResponseList";
    const isProfit =
      balance > 0 && item === "rightAccountGroupFetchResponseList";

    if (isLoss || isProfit) {
      return (
        <tr
          style={{
            cursor: "pointer",
            backgroundColor: `#DDEEFF`,
            marginTop: "1rem!important",
            border: "2px solid red",
          }}
          key={type}
        >
          <TableCell
            colSpan={6}
            style={{
              padding: "12px",
            }}
          >
            <Typography
              variant="small1"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <span
                style={{ color: isLoss ? "red" : "green", paddingLeft: "30px" }}
              >
                {isLoss ? "Loss" : "Profit"}
              </span>

              <span>
                <AmountInfo amount={balance} />
              </span>
            </Typography>
          </TableCell>
        </tr>
      );
    }

    return null;
  };

  return (
    <>
      <TableContainer
        style={{ overflow: "hidden" }}
        // style={{ marginTop: 20, minHeight: "200px", maxHeight: "650px" }}
      >
        <Grid container spacing={2}>
          {reversedFilteredData &&
          Object.keys(reversedFilteredData)?.length > 0 ? (
            Object.keys(reversedFilteredData)?.map((item) => {
              return (
                <Grid
                  item
                  md={6}
                  lg={6}
                  style={{
                    maxHeight: "calc(-350px + 100vh)",
                    overflow: "auto",
                  }}
                >
                  <Table aria-label="collapsible table" stickyHeader>
                    <div style={{ height: "9px" }}></div>
                    {reversedFilteredData?.[item].length > 0 ? (
                      <TableBody>
                        {reversedFilteredData &&
                          reversedFilteredData?.[item]?.map((row, index) => {
                            // Render other rows normally
                            return (
                              <Row
                                key={row.name}
                                childRow={row}
                                index={(pageNo - 1) * pageSize + index + 1}
                                handleRevert={handleRevert}
                                site={site}
                                level={0}
                                item={item}
                                color={"#DDEEFF"}
                                setLoading={setLoading}
                                successAlert={successAlert}
                                errorAlert={errorAlert}
                                navigateToLedger={navigateToLedger}
                                getApprovedBal={getApprovedBal}
                                tableData={data}
                                setData={setData}
                                siteId={siteId}
                                fromDate={fromDate}
                                toDate={toDate}
                                monthId={monthId}
                                showOpeningBalance={showOpeningBalance}
                                isBalanceWiseData={isBalanceWiseData}
                              />
                            );
                          })}

                        {/* Rest of  code */}
                        {siteId &&
                          netAmountArray &&
                          netAmountArray.length > 0 &&
                          renderNetAmountRow(
                            siteId,
                            item,
                            netAmountArray.find((val) => val.siteId == siteId)
                              ?.balance || 0,
                            item
                          )}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell
                            className="tableData"
                            colSpan={12}
                            sx={{ textAlign: "center" }}
                          >
                            No Data to Show
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    )}
                  </Table>
                </Grid>
              );
            })
          ) : (
            <>
              <Grid item md={6} lg={6}>
                <Table aria-label="collapsible table" stickyHeader>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell colSpan={12}>Revenue</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell
                        colSpan={12}
                        sx={{ textAlign: "center" }}
                      >
                        No Data to Show
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item md={6} lg={6}>
                <Table aria-label="collapsible table" stickyHeader>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell colSpan={12}>Expenses</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell
                        colSpan={12}
                        sx={{ textAlign: "center" }}
                      >
                        No Data to Show
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Grid>
            </>
          )}
        </Grid>
      </TableContainer>
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Grid item md={6} lg={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "6px",
              background: "#f3a0a0",
              height: "38px",
              alignItems: "center",
              padding: "0px 15px",
              fontWeight: "600",
              marginTop: "1rem",
            }}
          >
            <p>Total Assets</p>
            {reversedFilteredData?.leftAccountGroupFetchResponseList && (
              <p
                style={{
                  color: !getApprovedBal
                    ? calculateColor(
                        reversedFilteredData.leftAccountGroupFetchResponseList,
                        siteId,
                        "accountGroupCurrentBalance",
                        "openingBalance"
                      )
                    : calculateColor(
                        reversedFilteredData.leftAccountGroupFetchResponseList,
                        siteId,
                        "approvedAccountGroupCurrentBalance",
                        "approvedOpeningBalance"
                      ),
                }}
              >
                <AmountInfo
                  amount={
                    (!getApprovedBal
                      ? calculateAmount(
                          reversedFilteredData.leftAccountGroupFetchResponseList,
                          siteId,
                          "accountGroupCurrentBalance",
                          "openingBalance",
                          "assets"
                        )
                      : calculateAmount(
                          reversedFilteredData.leftAccountGroupFetchResponseList,
                          siteId,
                          "approvedAccountGroupCurrentBalance",
                          "approvedOpeningBalance",
                          "assets"
                        )) +
                    (netAmountArray &&
                    netAmountArray.length > 0 &&
                    netAmountArray.find((val) => val.siteId == siteId)
                      ?.balance < 0
                      ? netAmountArray.find((val) => val.siteId == siteId)
                          ?.balance
                      : 0)
                  }
                />
              </p>
            )}
          </div>
        </Grid>

        <Grid item md={6} lg={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "6px",
              textAlign: "center",
              fontWeight: 600,
              backgroundColor: "#a7f3a7",
              height: "38px",
              alignItems: "center",
              padding: "0px 15px",
              fontWeight: "600",
              marginTop: "1rem",
            }}
          >
            <p>Total Liabilities</p>

            {reversedFilteredData?.rightAccountGroupFetchResponseList && (
              <p
                style={{
                  color: !getApprovedBal
                    ? calculateColor(
                        reversedFilteredData.rightAccountGroupFetchResponseList,
                        siteId,
                        "accountGroupCurrentBalance",
                        "openingBalance"
                      )
                    : calculateColor(
                        reversedFilteredData.rightAccountGroupFetchResponseList,
                        siteId,
                        "approvedAccountGroupCurrentBalance",
                        "approvedOpeningBalance"
                      ),
                }}
              >
                <AmountInfo
                  amount={
                    (!getApprovedBal
                      ? calculateAmount(
                          reversedFilteredData.rightAccountGroupFetchResponseList,
                          siteId,
                          "accountGroupCurrentBalance",
                          "openingBalance",
                          "assets"
                        )
                      : calculateAmount(
                          reversedFilteredData.rightAccountGroupFetchResponseList,
                          siteId,
                          "approvedAccountGroupCurrentBalance",
                          "approvedOpeningBalance",
                          "assets"
                        )) +
                    (netAmountArray &&
                    netAmountArray.length > 0 &&
                    netAmountArray.find((val) => val.siteId == siteId)
                      ?.balance > 0
                      ? netAmountArray.find((val) => val.siteId == siteId)
                          ?.balance
                      : 0)
                  }
                />
              </p>
            )}
          </div>
        </Grid>
      </Grid>

      <Divider />
    </>
  );
}
